import React from "react";
import LogoImg from "../../assets/images/logo_icon_left.png";

export const LogoIconLeft = (
  props: React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >) => (
  <img
    src={LogoImg}
    height={18}
    width={129}
    alt="Pictal Health logo"
    {...props}
  />
);
