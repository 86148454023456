import { OutlineButton } from "./base/OutlineButton";
import { useDownloadMode } from "../context/DownloadModeContext";
import { analytics } from "../analytics";
import { EVENTS } from "../analytics/events";
import { Icon } from "./base/Icon";

export const DownloadButton = () => {
  const { toggleDownloadMode } = useDownloadMode();

  const handleClick = () => {
    toggleDownloadMode(true);
    analytics.track({
      event: EVENTS.CLICK_DOWNLOAD_START_BTN,
    });
  };

  return (
    <>
      <OutlineButton onClick={handleClick}>
        <Icon name="picture_as_pdf" />
        Download
      </OutlineButton>
    </>
  );
};