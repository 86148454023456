import { Button as ReakitButton } from "reakit";
import styled from "styled-components";
import { colors } from "../../constants/theme";

export const ButtonAsLink = styled(ReakitButton)({
  border: "none",
  outline: "none",
  cursor: "pointer",
  textAlign: "center",
  background: "transparent",
  textDecoration: 'underline',
  padding: 0,
  fontSize: 'inherit',
  color: colors.blue,
});
