import React from "react";
import styled from "styled-components";
import { colors } from "../../../constants/theme";
import { tickerHeight } from "../Ticker";
import { OUTLINE_BUTTON_LEFT_PADDING, OutlineButton } from "../../base/OutlineButton";
import { useDisplayData } from "../Timeline/DisplayDataContext";
import { uiConstants } from "../../../uiConstants";
import { Icon } from "../../base/Icon";

export const TOGGLE_BUTTON_HEIGHT = 40;
export const TOGGLE_BUTTON_LEFT = 15;

const TOGGLE_BUTTON_TOP =
  uiConstants.navbar.height + tickerHeight - TOGGLE_BUTTON_HEIGHT / 2;

export const StyledFilterToggleButton = styled(OutlineButton)({
  position: "fixed",
  left: TOGGLE_BUTTON_LEFT,
  top: TOGGLE_BUTTON_TOP,
  fontSize: 13,
  lineHeight: `${TOGGLE_BUTTON_HEIGHT}px`,
  color: colors.dark,
  height: TOGGLE_BUTTON_HEIGHT,
  zIndex: 2,
  pointerEvents: "all",
  display: "inline-flex",
  alignItems: "center",
  gap: 8,
  boxShadow: `0px 2px 6px rgba(15, 40, 63, 0.101449)`,
  paddingRight: OUTLINE_BUTTON_LEFT_PADDING,
});

const ShowList = styled('span')({
  display: 'flex',
  alignItems: 'center',
});

type FilterToggleButtonProps = React.ComponentProps<typeof OutlineButton>;

export const FilterToggleButton = (
  props: FilterToggleButtonProps
) => {
  const { hiddenDataCount } = useDisplayData();

  return (
    <StyledFilterToggleButton
      {...props}
      $buttonColors={{
        color: colors.dark,
        backgroundColor: colors.white,
        borderColor: colors.illustrationGrey,
      }}
      style={{paddingLeft: 0}}
    >
      <ShowList>
        <Icon
          name="chevron_right"
          style={{ fontSize: 36 }}
        />
        Show list
      </ShowList>
      {!!hiddenDataCount && (
        <span>
          <Icon name="visibility_off" style={{ verticalAlign: "middle" }} />
          {hiddenDataCount}
        </span>
      )}
    </StyledFilterToggleButton>
  );
};
