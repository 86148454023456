
import { DialogStateReturn, MenuButton, MenuStateReturn, useMenuState, usePopoverState } from "reakit";
import styled from "styled-components";

import { Button } from "../../base/Button";
import { ModalBody, ModalFooter, ModalHeader, ModalHeading } from "../../base/Modal";
import { colors } from "../../../constants/theme";
import React, { useEffect, useMemo, useState } from "react";
import { Field } from "./Field";
import { Input, InputSize } from "../../base/Input";
import { ChartCategory, GetSubCategoriesForUserQuery, SubCategory, useAddSubCategoryMutation, useEditSubCategoryMutation, useGetChartDataLazyQuery, useRemoveSubCategoryMutation } from "../../../data/graphql/generated/graphql";
import { Popover } from "../../base/Popover";

import { NeutralButton } from "../../base/NeutralButton";
import { useDebounce } from "react-use";
import { MyChartDataAccessContext } from "../../../context/MyChartDataAccessContext";
import { gql } from "@apollo/client";
import { DropdownMenu, DropdownMenuItem } from "../../base/DropdownMenu";
import { Tooltip, TooltipReference, useTooltipState } from "../../base/Tooltip";
import { Icon } from "../../base/Icon";

const StyledDot = styled("div")(
  ({
    $backgroundColor,
    $isActive,
    $showsHover,
  }: {
    $backgroundColor: string;
    $isActive?: boolean;
    $showsHover?: boolean;
  }) => ({
    width: 42,
    height: 42,
    borderRadius: "100%",
    backgroundColor: $backgroundColor,
    ...($isActive
      ? {
          boxShadow: `0 0 0 10px ${colors.light110}`,
        }
      : null),
    cursor: "pointer",
    flexShrink: 0,
    ...($showsHover && {":hover": {
      boxShadow: `0 0 0 10px ${colors.light}`,
    }}),
  })
);

const Dots = styled("div")({
  padding: 12,
  display: 'grid',
  gap: 20,
  gridTemplateColumns: 'repeat(3, 1fr)',
});

const dotColors = [colors.red, colors.tangerine, colors.yellow, colors.green, colors.forest, colors.blue, colors.navy, colors.burgundy, colors.purple];

const getNewColor = (existingColors: string[]) =>
  dotColors.find((color) => !existingColors.includes(color)) || colors.burgundy;

const Dot = ({ color, onClick }: { color: string, onClick: (color: string) => void }) => {
  const popover = usePopoverState({ placement: "bottom-start" });

  return (
    <>
      <Popover
        popover={popover}
        disclosure={<StyledDot $backgroundColor={color} />}
      >
        <Dots>
          {dotColors.map((dotColor) => (
            <StyledDot
              key={dotColor}
              $backgroundColor={dotColor}
              $isActive={dotColor === color}
              $showsHover={true}
              onClick={(e) => {
                e.preventDefault();
                popover.hide();
                onClick(dotColor);
              }}
            />
          ))}
        </Dots>
      </Popover>
    </>
  );
};

const StyledSymptomRow = styled(Field)({
  display: 'grid',
  gridTemplateColumns: 'max-content auto max-content',
  columnGap: 16,
  rowGap: 5,
  alignItems: 'center',
  marginBottom: 16,
});

const Instructions = styled('p')({
  fontSize: 16,
  lineHeight: '21px',
  marginBottom: 30,
});

const AddButton = styled(NeutralButton)({
  display: "flex",
  gap: 16,
  alignItems: "center",
  marginLeft: 46,
  marginBottom: 20,
  fontSize: 16,
});

export const EditSymptoms = ({
  onKeyPress,
  modalState,
  subCategories,
  onDone,
}: {
  onKeyPress: (e: React.KeyboardEvent<HTMLFormElement>) => void;
  modalState: DialogStateReturn;
  subCategories?: GetSubCategoriesForUserQuery["getSubCategoriesForUser"];
  onDone: () => void;
}) => {
  const [addSubCategoryMutation] = useAddSubCategoryMutation();
  const myChartDataAccessContext = React.useContext(MyChartDataAccessContext);
  const userId = myChartDataAccessContext.currentlyViewingUser?.id;
  const [error, setError] = useState(false);
  const [getChartDataLazyQuery, { data: getChartData }] =
    useGetChartDataLazyQuery();

  useEffect(() => {
    if (userId) {
      getChartDataLazyQuery({
        variables: {
          userId: parseInt(userId),
        }
      });
    }
  }, [getChartDataLazyQuery, userId]);

  const chartDataBySymptom = useMemo(
    () =>
      getChartData?.getChartDataForUser.reduce((counts, chartData) => {
        const subCategoryId = chartData.subCategory?.id;

        if (!subCategoryId) {
          return counts;
        }

        return {
          ...counts,
          [subCategoryId]:
            subCategoryId in counts ? counts[subCategoryId] + 1 : 1,
        };
      }, {} as { [key: string]: number }) || {},
    [getChartData]
  );

  const handleAdd = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (userId) {
      const color = subCategories
        ? getNewColor(subCategories.map((sc) => sc.color))
        : colors.burgundy;
      addSubCategoryMutation({
        variables: {
          subCategoryInput: {
            name: "New symptom group",
            color,
            chartCategory: ChartCategory.Symptoms,
            userId: parseInt(userId),
          },
        },
        update(cache, { data }) {
          const addSubCategory = data?.addSubCategory;

          cache.modify({
            fields: {
              getSubCategoriesForUser(existingSubCategoryData = []) {
                const newDataRef = cache.writeFragment({
                  data: addSubCategory,
                  fragment: gql`
                    fragment NewSubCategory on SubCategory {
                      id
                      chartCategory
                      name
                      color
                      userId
                    }
                  `,
                });
                return [...existingSubCategoryData, newDataRef];
              },
            },
          });
        },
      });
    }
  };

  const handleDone: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (!disabled) {
      onDone();
    }
  };

  const disabled = useMemo(
    () =>
      (subCategories && subCategories.some((symptom) => !symptom.name)) ||
      error,
    [subCategories, error]
  );

  const hasMaxSymptoms =
    subCategories && subCategories.length >= dotColors.length;

  return (
    <form onSubmit={handleDone} onKeyPress={onKeyPress}>
      <ModalHeader {...modalState} isDismissible={false}>
        <ModalHeading>Edit symptoms and colors</ModalHeading>
      </ModalHeader>
      <ModalBody {...modalState}>
        <Instructions>
          Keep it simple. Group symptoms if you can.
          <br />
          For example: ‘Physical health’ and ‘Mental health.’
        </Instructions>
        {subCategories &&
          subCategories.map((symptom) => (
            <SymptomRow
              key={symptom.id}
              symptom={symptom}
              onError={setError}
              totalSymptomCount={subCategories.length}
              noteCount={chartDataBySymptom[symptom.id]}
            />
          ))}
        {!hasMaxSymptoms && (
          <AddButton onClick={handleAdd} key="add" color={colors.dark85}>
            <Icon name="add" /> Add another
          </AddButton>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color={colors.burgundy}
          type="submit"
          disabled={disabled}
          tabIndex={0}
        >
          Done
        </Button>
      </ModalFooter>
    </form>
  );
};

const EllipsisButton = styled(NeutralButton)({
  display: "flex",
  height: "100%",
  alignItems: "center",
});

export const FieldError = styled("p")({
  marginTop: 3,
  marginBottom: 0,
  color: colors.red,
  fontSize: 13,
});

const SymptomError = styled(FieldError)({
  gridColumnStart: 2,
});

const SymptomRow = ({
  symptom,
  onError,
  totalSymptomCount,
  noteCount,
}: {
  symptom: SubCategory;
  onError: (error: boolean) => void;
  totalSymptomCount: number;
  noteCount?: number;
}) => {
  const [localSymptom, setLocalSymptom] = useState({ ...symptom });
  const [editSubCategoryMutation] = useEditSubCategoryMutation();
  const [removeSubCategoryMutation] = useRemoveSubCategoryMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const ellipsisMenuState = useMenuState();
  const [removeMessage, setRemoveMessage] = useState<string | null>(null);
  const removeTooltipState = useTooltipState({
    placement: 'bottom-start',
  });

  useEffect(() => {
    if (totalSymptomCount === 1) {
      setRemoveMessage("You must have at least one symptom");
    } else if (noteCount) {
      setRemoveMessage(
        "To remove: first make sure no notes belong to this symptom."
      );
    } else {
      setRemoveMessage(null);
    }
  }, [totalSymptomCount, noteCount]);

  const [, cancel] = useDebounce(
    () => {
      if (
        localSymptom.name !== symptom.name ||
        localSymptom.color !== symptom.color
      ) {
        // Need to remove the typename or mutation fails
        const { __typename, ...editFields } = localSymptom;
        editSubCategoryMutation({
          variables: {
            subCategoryInput: {
              ...editFields,
              id: parseInt(localSymptom.id),
            },
          },
        })
          .then(() => {
            setErrorMessage("");
            onError(false);
          })
          .catch((error) => {
            setErrorMessage(error.message);
            onError(true);
          });
      }
    },
    600,
    [localSymptom.name, localSymptom.color, symptom.name, symptom.color]
  );

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    cancel();

    setLocalSymptom((oldSymptom) => ({
      ...oldSymptom,
      name: e.target.value,
    }));
  };

  const handleColorChange = (newColor: string) => {
    cancel();
    setLocalSymptom((oldSymptom) => ({
      ...oldSymptom,
      color: newColor,
    }));
  };

  const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const id = parseInt(localSymptom.id);

    removeSubCategoryMutation({
      variables: {
        subCategoryInput: {
          id,
          userId: localSymptom.userId,
        },
      },
      update(cache) {
        const normalizedId = cache.identify({
          id,
          __typename: "SubCategory",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    }).catch((error) => {
      setErrorMessage(error.message);
      onError(true);
    });
  };

  return (
    <StyledSymptomRow>
      <Dot color={localSymptom.color} onClick={handleColorChange} />
      <Input
        id="eventName"
        name="eventName"
        placeholder={`Enter symptom group`}
        type="text"
        value={localSymptom.name}
        onChange={handleNameChange}
        size={InputSize.normal}
        $isInvalid={!localSymptom.name.length || !!errorMessage}
        tabIndex={0}
      />
      <MenuButton
        {...ellipsisMenuState}
        as={EllipsisButton}
        color={colors.select}
      >
        <Icon name="more_horiz" />
      </MenuButton>
      <DropdownMenu aria-label="More" {...ellipsisMenuState}>
        {!!removeMessage ? (
          <>
            <TooltipReference
              {...removeTooltipState}
              style={{ outline: "none" }}
            >
              <RemoveButton {...ellipsisMenuState} onClick={handleRemove} disabled={true} />
            </TooltipReference>
            {removeTooltipState.visible && (
              <Tooltip
                {...removeTooltipState}
                style={{ zIndex: 100, width: 200, padding: 12 }}
              >
                {removeMessage}
              </Tooltip>
            )}
          </>
        ) : (
          <RemoveButton {...ellipsisMenuState} onClick={handleRemove} />
        )}
      </DropdownMenu>
      {errorMessage && <SymptomError>{errorMessage}</SymptomError>}
    </StyledSymptomRow>
  );
};


const RemoveButton = ({
  onClick,
  disabled,
  ...ellipsisMenuState
}: { onClick: React.MouseEventHandler<HTMLButtonElement>; disabled?: boolean } & MenuStateReturn) => (
  <DropdownMenuItem {...ellipsisMenuState} onClick={onClick} disabled={!!disabled}>
    <Icon name="delete" /> Remove
  </DropdownMenuItem>
);