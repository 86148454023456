import { MenuButton, useMenuState } from "reakit";
import styled from "styled-components";
import { DropdownMenu, DropdownMenuItem, DropdownMenuSeparator } from "../../base/DropdownMenu";
import { Icon } from "../../base/Icon";
import { NeutralButton } from "../../base/NeutralButton";
import { colors } from "../../../constants/theme";
import { ChartDataAccessToUserQuery, ChartDataAccessType, useEditChartDataAccessMutation } from "../../../data/graphql/generated/graphql";
import { AccessIcon } from "../../base/AccessIcon";
import { useAuth } from "../../../context/AuthContext";
import { ChartDataAccessTypeDisplayText } from "../../../context/MyChartDataAccessContext";

const StyledPersonWithAccess = styled("li")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "13px 0",
  borderBottom: `1px solid ${colors.light110}`,
});

const PersonWithAccessName = styled("div")({
  fontFamily: "Graphik Medium",
  fontWeight: 500,
  fontSize: 16,
  lineHeight: "20px",
  color: colors.dark,
  flexGrow: 1,
});

const PersonWithAccessAccess = styled(NeutralButton)({
  padding: 0,
  display: "flex",
  gap: 5,
  alignItems: "center",
  fontFamily: "Graphik Medium",
  fontWeight: 500,
  fontSize: 16,
  lineHeight: "18px",
  color: colors.dark75,
});

export type ChartDataAccessPerson = ChartDataAccessToUserQuery['chartDataAccessToUser'][0];

const selectableAccessTypes = [
  ChartDataAccessType.Read,
  ChartDataAccessType.Write,
];

export const PersonWithAccess = ({
  person,
  onRemoveAccess,
  currentlyViewingId,
}: {
  person: ChartDataAccessPerson;
  onRemoveAccess: (person: ChartDataAccessPerson) => void;
  currentlyViewingId: string;
}) => {
  const auth = useAuth();
  const currentUser = auth?.currentUser;

  const menuState = useMenuState();

  const [editChartDataAccessMutation] = useEditChartDataAccessMutation();

  if (!person) {
    return null;
  }

  const handleUpdateAccess = async (accessType: ChartDataAccessType) => {
    await editChartDataAccessMutation({
      variables: {
        chartDataAccessInput: {
          id: parseInt(person.id),
          aboutId: parseInt(currentlyViewingId),
          accessorId: parseInt(person.accessor.id),
          accessType,
        },
      },
    });
    menuState.hide();
  };

  // Disable dropdown for Owner: no one can modify them
  // Disable dropdown for current user: they can't modify themselves
  const shouldDisableDropdown = person.accessType === ChartDataAccessType.Owner || person.accessor.id === currentUser?.id;

  return (
    <StyledPersonWithAccess>
      <PersonWithAccessName>
        {person.accessor.displayName}
        {person.accessor.id === currentUser?.id ? " (you)" : ""}
      </PersonWithAccessName>
      <MenuButton as={PersonWithAccessAccess} disabled={shouldDisableDropdown} {...menuState}>
        <AccessIcon accessType={person.accessType} />
        {ChartDataAccessTypeDisplayText[person.accessType]}
        {!shouldDisableDropdown && <Icon name="keyboard_arrow_down" />}
      </MenuButton>
      <DropdownMenu aria-label="Menu" {...menuState}>
        {selectableAccessTypes.map((accessType) => {
          const isCurrentAccessType = person.accessType === accessType;

          return (
            <DropdownMenuItem
              {...menuState}
              onClick={() => handleUpdateAccess(accessType)}
              key={accessType}
              disabled={isCurrentAccessType}
            >
              <Icon
                name="check"
                style={{
                  visibility: isCurrentAccessType ? "visible" : "hidden",
                }}
              />
              {ChartDataAccessTypeDisplayText[accessType]}
            </DropdownMenuItem>
          );
        })}
        <DropdownMenuSeparator />
        <DropdownMenuItem
          {...menuState}
          onClick={() => onRemoveAccess(person)}
          key="removeAccess"
        >
          Remove access
        </DropdownMenuItem>
      </DropdownMenu>
    </StyledPersonWithAccess>
  );
};