import styled from "styled-components";

import { LogoFull } from "./base/LogoFull";

const FullScreenCenter = styled('div')({
  height: '100vh',
  width: '100vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const AuthLoading = () => (
  <FullScreenCenter><LogoFull /></FullScreenCenter>
);