import styled from "styled-components";
import { RadioStateReturn } from "reakit/Radio";

import { Field } from "../DataModal/Field";
import { Label } from "../DataModal/Label";
import { ThreeButtons } from "./ThreeButtons";
import { ChartCategory } from "../../../data/graphql/generated/graphql";
import { ChartConfigType } from "../config";
import { useMedia } from "react-use";
import { Icon } from "../../base/Icon";

const Paragraph = styled.p({
  marginTop: 0,
});

type CategoryStrings = {
  text: string;
};

const getConfig = (category: ChartConfigType): CategoryStrings | undefined => {
  if (category.category === ChartCategory.TreatmentsSelfCare) {
    return {
      text: "Did this treatment or self-care practice help you feel better?",
    };
  }

  if (category.category === ChartCategory.Diagnoses) {
    return {
      text: "Do you agree with this diagnosis?",
    };
  }
};

interface ThreeButtonsFieldProps extends RadioStateReturn {
  category: ChartConfigType;
  readOnly: boolean;
};

export const ThreeButtonsField = ({
  category,
  readOnly,
  ...radioState
}: ThreeButtonsFieldProps) => {
  const strings = getConfig(category);
  const isMobile = useMedia("(max-width: 500px)");

  if (!strings) {
    return null;
  }

  return (
    <Field withLabel={true}>
      {!isMobile && <Label><Icon name="not_listed_location" /></Label>}
      <div>
        <Paragraph>{strings.text}</Paragraph>
        <ThreeButtons readOnly={readOnly} {...radioState} />
      </div>
    </Field>
  );
};
