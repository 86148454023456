import { forwardRef, useMemo, useState } from "react";
import ReactDatePicker, { CalendarContainer } from "react-datepicker";
import { parseISO } from "date-fns";
import { Checkbox } from "reakit/Checkbox";

import "react-datepicker/dist/react-datepicker.css";
import { Input } from "../../base/Input";
import styled from "styled-components";
import { colors } from "../../../constants/theme";

export const CustomInput = forwardRef((
    // FIX ME
    props: any,
    ref: any
  ) => <Input {...props} ref={ref} />);

export enum WhichDate {
  start,
  end
}

type DatePickerProps = {
  selected?: string;
  onChange: (data: Date) => void;
  onCurrentChange?: (isCurrent: boolean) => void;
  placeholderText?: string;
  whichDate?: WhichDate;
  startDate?: string;
  endDate?: string;
  minDate?: string;
  isCurrent?: boolean | null;
};

const StartDateContainer = styled(CalendarContainer)({
  boxShadow: "0px 2px 10px rgb(15 40 63 / 20%)",
});

const EndDateContainer = styled("div")({
  borderRadius: 3,
  padding: 10,
  background: colors.white,
  boxShadow: "0px 2px 10px rgb(15 40 63 / 20%)",
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

const TodayContainer = styled("label")({
  fontFamily: "Graphik Regular",
  fontWeight: 400,
  color: colors?.dark85,
});

export const DatePicker = ({
  selected,
  onChange,
  onCurrentChange,
  placeholderText,
  whichDate,
  startDate,
  endDate,
  minDate,
  isCurrent,
}: DatePickerProps) => {
  const DatePickerContainer = ({
    className,
    children,
  }: {
    className?: string | undefined;
    children?: React.ReactNode;
  }) => {
    const [isChecked] = useState(!!isCurrent);
    if (whichDate === WhichDate.start) {
      return (
        <StartDateContainer className={className}>
          {children}
        </StartDateContainer>
      );
    }

    const handleTodayChange = () => {
      if (onCurrentChange) {
        onCurrentChange(!isChecked);
      }
    };

    return (
      <EndDateContainer>
        <TodayContainer>
          <Checkbox
            onChange={handleTodayChange}
            checked={isChecked}
          />{" "}
          Today
        </TodayContainer>
        <CalendarContainer className={className}>{children}</CalendarContainer>
      </EndDateContainer>
    );
  };

  const selectedDate = useMemo(() => {
    if (isCurrent) {
      return new Date();
    }

    return selected ? parseISO(selected) : null;
  }, [isCurrent, selected]);

  const computedEndDate = useMemo(() => {
    if (isCurrent) {
      return new Date();
    }

    return endDate ? parseISO(endDate) : null;
  }, [isCurrent, endDate]);

  return (
    // Needs a wrapper so popup stays contained
    <div>
      <ReactDatePicker
        selected={selectedDate}
        onChange={onChange}
        customInput={<CustomInput />}
        placeholderText={placeholderText}
        selectsStart={whichDate === WhichDate.start}
        selectsEnd={whichDate === WhichDate.end}
        startDate={startDate ? parseISO(startDate) : null}
        endDate={computedEndDate}
        minDate={minDate ? parseISO(minDate) : null}
        calendarContainer={DatePickerContainer}
        disabledKeyboardNavigation
      />
    </div>
  );
};
