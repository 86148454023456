import styled from "styled-components";
import { Label } from "./Label";

export const Field = styled("div")(
  ({
    withLabel,
    vertical = false,
  }: {
    withLabel?: boolean;
    vertical?: boolean;
  }) => ({
    marginBottom: 33,
    width: '100%',
    ...(withLabel && {
      display: "flex",
    }),
    ...(vertical
      ? {
          flexDirection: "column" as const,
          gap: 10,
        }
      : {
          [Label]: {
            flex: "0 0 45px",
            paddingLeft: 17,
          },
        }
      ),
  })
);
