import React, { useMemo } from "react";
import { Axis, AxisScale, Orientation, TickRendererProps } from "@visx/axis";
import styled from 'styled-components';
import { transparentize } from "polished";
import { ScaleInput } from "@visx/scale";
import { Text } from "@visx/text";
import { format, startOfMonth, startOfYear } from "date-fns";
import { enUS } from "date-fns/locale";
import { TimeScale } from "./helpers";
import { colors } from "../../constants/theme";

const tickColor = colors.light110;
const tickLabelColorDark = colors.dark85;
const tickLabelColorLight = colors.dark75;
export const tickerHeight = 34;

const StickyTicker = styled.div`
  width: 100%;
  height: ${tickerHeight}px;
  z-index: 1;
  background: ${transparentize(0.2, colors.light)};
`;

const tickLabelProps = {
  fontFamily: "Graphik Regular",
  textAnchor: "middle",
} as const;

export type TickerProps = {
  width: number;
  timeScale: TimeScale;
};

const TickComponent = ({formattedValue, ...rest}: TickRendererProps) => {
  const segments = (formattedValue || '').split("-");

  if (segments.length === 1) {
    const value = segments[0];

    return (
      <Text
        key={`axis-${value}`}
        {...rest}
        {...tickLabelProps}
        fill={tickLabelColorDark}
        fontSize={13}
        dy={6}
      >
        {value}
      </Text>
    );
  }

  return (
    <>
      {segments.map((s, i) => (
        <Text
          key={`axis-${i}-${s}-${formattedValue}`}
          {...rest}
          {...tickLabelProps}
          fontSize={i > 0 ? 10 : 12}
          dy={i > 0 ? 11 : 0}
          fill={i > 0 ? tickLabelColorLight : tickLabelColorDark}
        >
          {s}
        </Text>
      ))}
    </>
  );
};

export const Ticker = ({ width, timeScale }: TickerProps) => {
  const axis: ScaleInput<AxisScale> = useMemo(() => {
    return {
      scale: timeScale,
      // TickComponent gets passed a string, split it with a hyphen
      tickFormat: (date: Date) => {
        if (width < 100) {
          return '';
        }

        return startOfMonth(date) < date
          ? format(date, "LLL d-yyyy", {
              locale: enUS,
            })
          : startOfYear(date) < date
          ? format(date, "LLL -yyyy", {
              locale: enUS,
            })
          : format(date, "yyyy", {
              locale: enUS,
            });
      },
    };
  }, [width, timeScale]);

  if (width < 10) {
    return null;
  }

  return (
    <StickyTicker>
      <svg id="ticker" width={width} height={tickerHeight}>
        <g>
          <Axis
            orientation={Orientation.bottom}
            scale={axis.scale}
            tickFormat={axis.tickFormat}
            stroke={tickColor}
            tickComponent={TickComponent}
            tickStroke={tickColor}
            // animationTrajectory={"outside"}
          />
        </g>
      </svg>
    </StickyTicker>
  );
};
