import { Range } from 'react-range';
import styled, { CSSObject } from 'styled-components';
import { transparentize } from "polished";

import { colors } from "../../constants/theme";
import {
  getBubbleRadius,
  getBubbleValue,
  BUBBLE_VALUE_MAX,
  BUBBLE_VALUE_MIN,
} from "./Charts/ChartBubble";
import { ColorConfig, getTransparentColor } from './config';

const STEP = .1;

const BUBBLE_RADIUS_MIN = getBubbleRadius(BUBBLE_VALUE_MIN);
const BUBBLE_RADIUS_MAX = getBubbleRadius(BUBBLE_VALUE_MAX);
const TRACK_WIDTH = '100%';
const TRACK_HEIGHT = 10;
const TRACK_BACKGROUND_HEIGHT = BUBBLE_RADIUS_MAX * 2;
const TRACK_MINIMUM_BACKGROUND_POSITION_TOP =
  TRACK_BACKGROUND_HEIGHT / 2 - BUBBLE_RADIUS_MIN;
const TRACK_MINIMUM_BACKGROUND_POSITION_BOTTOM =
  TRACK_BACKGROUND_HEIGHT / 2 + BUBBLE_RADIUS_MIN;
const SLIDER_INPUT_HEIGHT = TRACK_BACKGROUND_HEIGHT;

const getTrackBackgroundColor = (color: string) => transparentize(.85, color);

const Wrap = styled("div")<{ readOnly: boolean }>(({ readOnly }) => ({
  height: SLIDER_INPUT_HEIGHT,
  padding: "10px 0",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  ...readOnly && ({
    pointerEvents: 'none',
  })
}));

const Track = styled("div")(
  ({ backgroundColor }: { backgroundColor: string }) =>
    ({
      width: TRACK_WIDTH,
      "::before": {
        content: '""',
        position: "absolute",
        left: 0,
        width: `calc(${TRACK_WIDTH} - ${BUBBLE_RADIUS_MAX}px)`,
        height: SLIDER_INPUT_HEIGHT,
        clipPath: `polygon(${BUBBLE_RADIUS_MIN}px ${TRACK_MINIMUM_BACKGROUND_POSITION_TOP}px, 100% 0%, 100% 100%, ${BUBBLE_RADIUS_MIN}px ${TRACK_MINIMUM_BACKGROUND_POSITION_BOTTOM}px)`,
        top: TRACK_HEIGHT / 2,
        transform: "translateY(-50%)",
        background: backgroundColor,
      },
    } as CSSObject)
);

const TrackLine = styled("div")({
  height: TRACK_HEIGHT,
  width: `calc(${TRACK_WIDTH} - ${BUBBLE_RADIUS_MAX + BUBBLE_RADIUS_MIN}px)`,
  marginLeft: BUBBLE_RADIUS_MIN,
  background: colors.light110,
  alignSelf: "center",
});

export const SliderInput = ({
  value,
  onChange,
  color,
  readOnly,
}: {
  value: number;
  onChange: (newValue: number) => void;
  color: ColorConfig;
  readOnly: boolean;
}) => {
  return (
    <Wrap readOnly={readOnly}>
      <Range
        values={[value]}
        step={STEP}
        min={BUBBLE_VALUE_MIN}
        max={BUBBLE_VALUE_MAX}
        onChange={(values) => onChange(values[0])}
        renderTrack={({ props, children }) => (
          <Track
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
            }}
            backgroundColor={getTrackBackgroundColor(color.fill.color)}
          >
            <TrackLine ref={props.ref}>{children}</TrackLine>
          </Track>
        )}
        renderThumb={({ props, isDragged }) => {
          const radius = getBubbleRadius(getBubbleValue(value));
          const diameter = radius * 2;

          return (
            <div
              {...props}
              style={{
                backgroundColor: isDragged
                  ? getTransparentColor(color.fill)
                  : color.fill.color,
                height: diameter,
                width: diameter,
                borderRadius: "50%",
                ...props.style,
              }}
            />
          );
        }}
      />
    </Wrap>
  );
};