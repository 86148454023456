import { ApolloClient, createHttpLink, from, InMemoryCache } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import { expireAuth, getAccessToken } from "../context/AuthContext";

const uri = process.env.REACT_APP_API_URL;

const httpLink = createHttpLink({
  uri,
});

const authLink = setContext((_, { headers }) => {
  const accessToken = getAccessToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
    }
  }
});

// Log any GraphQL errors or network error that occurred
const errorLink =
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        Sentry.captureMessage(message, {
          extra: {
            ...(locations && { locations }),
            ...(path && { path }),
            ...(extensions && { extensions }),
          },
        });

        switch (extensions?.code) {
          case "UNAUTHENTICATED":
            expireAuth();
            break;
        }
      });
    if (networkError) {
      Sentry.captureException(networkError);
    }
  });

export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});
