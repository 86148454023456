import React, { FormEventHandler, useState } from "react";
import styled, { CSSObject } from "styled-components";
import { colors } from "../constants/theme";
import { isCognitoErrorFromUnconfirmedUser, useAuth } from "../context/AuthContext";
import { Button, ButtonSize } from "./base/Button";

import { Field } from "./timeline/DataModal/Field";
import { Label } from "./timeline/DataModal/Label";
import { FormAlert } from "./base/FormAlert";
import { Loader } from "./base/Loader";
import { ButtonAsLink } from "./base/ButtonAsLink";
import { Input } from "./base/Input";
import { LogoFull } from "./base/LogoFull";
import { LogoIconLeft } from "./base/LogoIconLeft";
import { getReadableAuthError } from "../helpers/authErrors";
import { RouteComponentProps, WindowLocation } from "@reach/router";
import { routes } from "../App";
import { analytics } from "../analytics";
import { EVENTS } from "../analytics/events";

const wrapperStyles: CSSObject = {
  width: 303,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
};

export const StyledWrapper = styled("div")({
  ...wrapperStyles,
});

const StyledLogoIconLeft = styled(LogoIconLeft)({
  marginTop: 60,
});

export const LoginButton = ({isLoading, ...rest}: React.ComponentProps<typeof Button> & {
  isLoading?: boolean;
}) => {
  if (isLoading) {
    return <Loader />;
  }

  return <Button {...rest} />;
};

export const LogoIconLeftLink = () => <a href="https://pictalhealth.com" target="_blank" rel="noreferrer"><StyledLogoIconLeft /></a>;

export const Wrapper = ({
  onSubmit,
  children,
}: {
  onSubmit: FormEventHandler<HTMLFormElement>;
  children: React.ReactNode;
}) => (
  <form onSubmit={onSubmit}>
    <StyledWrapper>
      <LogoFull />
      {children}
      <LogoIconLeftLink />
    </StyledWrapper>
  </form>
);

export const Header = styled('h1')({
  fontSize: 28,
  lineHeight: '37px',
  fontWeight: 500,
  marginTop: 20,
  marginBottom: 20,
});

export const IntroText = styled('p')({
  textAlign: 'center',
  marginTop: 0,
  marginBottom: 42,
});

export const AuthLink = styled("p")({
  textAlign: "center",
  marginTop: 42,
  marginBottom: 0,
  fontSize: 16,
});

const defaultInfo = {
  email: "",
  password: "",
};

type LocationState = {
  error?: string;
} | null;

interface Props extends RouteComponentProps {
  location?: WindowLocation<LocationState>
}

export const Login = (props: Props) => {
  const [state, setState] = useState(defaultInfo);
  const [error, setError] = useState(props.location?.state?.error || '');
  const [isLoading, setIsLoading] = useState(false);

  const auth = useAuth();

  const handleChange =
    (field: keyof typeof defaultInfo) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({
        ...state,
        [field]: e.target.value,
      });
    };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    auth
      ?.login({ username: state.email, password: state.password })
      .catch((e) => {
        if (e && isCognitoErrorFromUnconfirmedUser(e.error)) {
          // Send them to confirm. We don't re-send an email, they
          // can do it again if they need to
          props.navigate?.(routes.confirmEmail);
        } else {
          setIsLoading(false);
          if (!e.success) {
            setError(getReadableAuthError(e.error));
          }
        }
      });

    analytics.track({
      event: EVENTS.CLICK_AUTH_LOGIN_BTN,
    });
  };

  const handleSignUp: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    props.navigate?.(routes.signup);
  };

  const handleResetPassword: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();

    props.navigate?.(routes.resetPassword);
  };

  return (
    <Wrapper onSubmit={handleSubmit}>
      <Header>Log In</Header>
      <Field withLabel={true} vertical={true}>
        <Label>Email</Label>
        <Input
          type="email"
          value={state.email}
          onChange={handleChange("email")}
        />
      </Field>
      <Field withLabel={true} vertical={true}>
        <Label>Password</Label>
        <Input
          type="password"
          value={state.password}
          onChange={handleChange("password")}
        />
      </Field>
      <FormAlert message={error} />
      <LoginButton
        size={ButtonSize.large}
        type="submit"
        color={colors.burgundy}
        isLoading={isLoading}
      >
        Log In
      </LoginButton>
      <AuthLink>
        Need an account?{" "}
        <ButtonAsLink onClick={handleSignUp}>Sign Up</ButtonAsLink>
      </AuthLink>
      <AuthLink>
        Forgot your password?{" "}
        <ButtonAsLink onClick={handleResetPassword}>
          Reset password
        </ButtonAsLink>
      </AuthLink>
    </Wrapper>
  );
};
