import styled from "styled-components";
import { colors } from "../../constants/theme";
import { Button, ButtonSize } from "../base/Button";
import { analytics } from "../../analytics";
import { EVENTS } from "../../analytics/events";
import { Icon } from "../base/Icon";

const PositionedZoom = styled("div")({
  position: "fixed",
  borderRadius: 4,
  bottom: 30,
  right: 10,
  display: "flex",
  flexDirection: "column",
  zIndex: 1,
  boxShadow: `0px 2px 6px rgba(15, 40, 63, 0.101449)`,
});

const ZoomButton = styled(Button)({
  fontSize: 32,
  color: colors.select,
  border: `1px solid ${colors.light110}`,
  "&:first-of-type": {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  "&:last-of-type": {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginTop: -1,
  },
});

type ZoomControlsProps = {
  onZoomIn: () => void;
  onZoomOut: () => void;
};

export const ZoomControls = ({ onZoomIn, onZoomOut }: ZoomControlsProps) => {
  const handleInClick = () => {
    onZoomIn();
    analytics.track({
      event: EVENTS.TIMELINE_INTERACTION_ZOOM,
      properties: {
        zoomMethod: "buttonClick",
        zoomDirection: "in",
      },
    });
  };

  const handleOutClick = () => {
    onZoomOut();
    analytics.track({
      event: EVENTS.TIMELINE_INTERACTION_ZOOM,
      properties: {
        zoomMethod: "buttonClick",
        zoomDirection: "out",
      },
    });
  };

  return (
    <PositionedZoom>
      <ZoomButton onClick={handleInClick} size={ButtonSize.small}>
        <Icon name="add" />
      </ZoomButton>
      <ZoomButton onClick={handleOutClick} size={ButtonSize.small}>
        <Icon name="remove" />
      </ZoomButton>
    </PositionedZoom>
  );
};
