import styled from "styled-components";
import { colors } from "../../constants/theme";

export enum AlertStyle {
  'alert',
  'success',
}

const StyledAlert = styled("div")<{ alertStyle: AlertStyle }>(({ alertStyle }) => ({
  borderRadius: 4,
  ...(alertStyle === AlertStyle.alert && {
    borderColor: colors.red,
    color: colors.red,
    backgroundColor: "#FFF0EF",
  }),
  ...(alertStyle === AlertStyle.success && {
    borderColor: colors.blue,
    color: colors.blue,
    backgroundColor: "#F2F6FE",
  }),
  padding: 24,
  fontSize: 16,
  lineHeight: "24px",
  marginTop: 20,
  marginBottom: 20,
}));

export const FormAlert = ({ message, alertStyle = AlertStyle.alert }: { message: string, alertStyle?: AlertStyle }) => {
  if (!message) {
    return null;
  }
  return (
    <StyledAlert alertStyle={alertStyle}>{message}</StyledAlert>
  );
};
