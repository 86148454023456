import { coerceNumber, scaleTime } from "@visx/scale";
import { ScaleTime } from "d3-scale";
import { format, startOfToday } from "date-fns";
import enUS from "date-fns/locale/en-US";
import { ChartDataDisplay } from "./config";
import { generateDisplayDateFromValues } from "../../helpers/dates";

// This is a fill-in for a "birthday" type value
const tempStart = -302316685;

export const getDateStart = (cd: ChartDataDisplay) => {
  if (cd.timestampStart) {
    return new Date(cd.timestampStart);
  }

  if (cd.always) {
    return new Date(tempStart * 1000);
  }

  throw new Error("Invalid date start");
};

export const getDateEnd = (cd: ChartDataDisplay) => {
  if (cd.timestampEnd) {
    return new Date(cd.timestampEnd);
  }

  if (cd.current) {
    return startOfToday();
  }

  return null;
};

export const formatDate = (date: Date) => format(date, "M/d/yyyy", {
  locale: enUS,
});

export type DisplayDates = Partial<Pick<ChartDataDisplay,
  'timestampStart' |
  'displayStartMonth' |
  'displayStartDay' |
  'displayStartYear' |
  'always' |
  'current' |
  'timestampEnd' |
  'displayEndMonth' |
  'displayEndDay' |
  'displayEndYear'
>>;

export const getDateDisplayStart = (dataDisplay: DisplayDates) => {
  if (dataDisplay.timestampStart) {
    return generateDisplayDateFromValues({
      month: dataDisplay.displayStartMonth,
      day: dataDisplay.displayStartDay,
      year: dataDisplay.displayStartYear,
    });
  }

  if (dataDisplay.always) {
    return "Always";
  }

  throw new Error("Invalid date start");
};

export const getDateDisplayEnd = (dataDisplay: DisplayDates) => {
  if (dataDisplay.timestampEnd) {
    return generateDisplayDateFromValues({
      month: dataDisplay.displayEndMonth,
      day: dataDisplay.displayEndDay,
      year: dataDisplay.displayEndYear,
    });
  }

  if (dataDisplay.current) {
    return "Present";
  }

  return null;
};

export const flattenDates = (chartDataDisplayArray: ChartDataDisplay[]) =>
  chartDataDisplayArray.reduce((datesSoFar, data) => {
    const dateStart = getDateStart(data);
    const dateEnd = getDateEnd(data);

    return [
      ...datesSoFar,
      ...(dateStart ? [dateStart] : []),
      ...(dateEnd ? [dateEnd] : []),
    ];
  }, [] as Date[]);

export type GetTimeScale = {
  dates: Date[];
  xMin: number;
  xMax: number;
};

export const getTimeScale = ({ dates, xMin, xMax }: GetTimeScale) => {
  if (!dates.length) {
    throw Error("Invalid dates given to getTimeScale");
  }

  return scaleTime({
    range: [xMin, xMax],
    domain: getMinMax(dates),
  });
};

export const getMinMax = (vals: (number | { valueOf(): number })[]) => {
  const numericVals = vals.map(coerceNumber);
  return [Math.min(...numericVals), Math.max(...numericVals)];
};

export type TimeScale = ScaleTime<number, number>;

export const getLabel = (data: { label?: string | null }) => data.label || '';
