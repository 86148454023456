import styled from "styled-components";
import { textStyles } from "./Input";

export const Textarea = styled("textarea")({
  ...textStyles,
  height: 107,
  resize: "none",
  paddingTop: 10,
  paddingBottom: 10,
  fontSize: 18,
  lineHeight: "24px",
  width: "100%",
});
