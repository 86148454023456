import * as Sentry from "@sentry/react";

// Cognito is notorious for providing bad error messages. Replace them here.
const cognitoErrorsByMessage: { [key: string]: string } = {
  "PreSignUp failed with error Invalid signup code.":
    "Please enter a valid signup code.",
  "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$":
    "Your password needs at least 8 characters and at least 1 number, special character, upper-case letter, and lower-case letter.",
};

const cognitoErrorsByCode: { [key: string]: string } = {
  InvalidParameterException: "Please fill out all fields.",
};

export const getReadableAuthError = (error: any) => {
  const code = error?.code || error?.name;
  const message = error?.message;
  const readableError =
    (message && cognitoErrorsByMessage[message]) ||
    (code && cognitoErrorsByCode[code]);

  if (!readableError) {
    Sentry.captureMessage("No readable error message for Cognito error", {
      extra: {
        error,
      },
    });
  }

  return readableError || message || 'Something went wrong, please try again.';
};
