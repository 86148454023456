import React, { useState } from "react";
import { colors } from "../constants/theme";
import { useAuth } from "../context/AuthContext";
import { ButtonSize } from "./base/Button";
import { Field } from "./timeline/DataModal/Field";
import { Label } from "./timeline/DataModal/Label";
import { AlertStyle, FormAlert } from "./base/FormAlert";
import { AuthLink, Header, IntroText, LoginButton, Wrapper } from "./Login";
import { ButtonAsLink } from "./base/ButtonAsLink";
import { Input } from "./base/Input";
import { getReadableAuthError } from "../helpers/authErrors";
import { RouteComponentProps } from "@reach/router";
import { routes } from "../App";

const defaultInfo = {
  confirmCode: "",
};

export const ConfirmEmail = (props: RouteComponentProps) => {
  const [state, setState] = useState(defaultInfo);
  const [error, setError] = useState("");
  const [resend, setResend] = useState('');
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange =
    (field: keyof typeof defaultInfo) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({
        ...state,
        [field]: e.target.value,
      });
    };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResend('');
    setError('');

    auth
      ?.confirm({
        confirmCode: state.confirmCode,
      })
      // We don't actually need to handle anything here. Users will
      // be authed now and will go to the authed side of the app
      .then(() => {})
      .catch((e) => {
        setIsLoading(false);
        if (e.error === "NEEDS_TO_FINISH_LOGIN") {
          // User is confirmed, but isn't authed.
          // Send them back to login
          props.navigate?.(routes.login, {
            state: {
              error:
                "Your email has been confirmed. Please log in to complete the process.",
            },
          });
        } else if (!e.success) {
          setError(getReadableAuthError(e.error));
        }
      });
  };

  const handleResendConfirmation: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    auth
      ?.resendConfirm()
      .then((e) => {
        setIsLoading(false);
        setResend("Confirmation code resent");
      })
      .catch((e) => {
        setIsLoading(false);
        if (!e.success) {
          if (e.error) {
            if (e.error === "NEEDS_TO_LOGIN_FIRST") {
              // We don't know who this user is.
              // Send them back to login
              props.navigate?.(routes.login, {
                state: {
                  error:
                    "Please log in, then resend a new code to confirm your email.",
                },
              });
            } else {
              setError(getReadableAuthError(e.error));
            }
          }
        }
      });
  };

  const handleLogin: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    props.navigate?.(routes.login);
  };

  return (
    <Wrapper onSubmit={handleSubmit}>
      <Header>Confirmation code</Header>
      <IntroText>
        We sent you an email with a confirmation code. Please enter it below
      </IntroText>
      <Field withLabel={true} vertical={true}>
        <Label>Confirmation Code</Label>
        <Input
          type="confirmCode"
          value={state.confirmCode}
          onChange={handleChange("confirmCode")}
        />
      </Field>{" "}
      <FormAlert message={error} />
      <FormAlert message={resend} alertStyle={AlertStyle.success} />
      <LoginButton
        size={ButtonSize.large}
        type="submit"
        color={colors.burgundy}
        isLoading={isLoading}
      >
        Submit
      </LoginButton>
      <AuthLink>
        <ButtonAsLink onClick={handleResendConfirmation}>
          Resend Code
        </ButtonAsLink>
      </AuthLink>
      <AuthLink>
        Or <ButtonAsLink onClick={handleLogin}>Log In</ButtonAsLink>
      </AuthLink>
</Wrapper>
  );
};
