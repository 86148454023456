import styled from "styled-components";
import { colors } from "../../constants/theme";

export const Pill = styled("div")(
  ({
    $color = colors.dark85,
    $backgroundColor = colors.light90,
    $dotColor,
  }: {
    $color?: string;
    $backgroundColor?: string;
    $dotColor?: string;
  }) => ({
    display: "inline-flex",
    gap: 5,
    alignItems: "center",
    borderRadius: 30,
    color: $color,
    backgroundColor: $backgroundColor,
    padding: '0 9px',
    fontSize: 14,
    lineHeight: 1,
    height: 32,
    ...($dotColor && ({
      "::before": {
        display: "block",
        content: '""',
        width: 12,
        height: 12,
        borderRadius: "100%",
        backgroundColor: $color,
        opacity: 0.6,
      },
    })),
  })
);
