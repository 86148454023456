import React from "react";
import styled from "styled-components";

import { Tooltip, TooltipProps, TooltipReference, TooltipReferenceHTMLProps } from "../base/Tooltip";
import { SubCategory } from "../../data/graphql/generated/graphql";
import { Pill } from "../base/Pill";

const Wrap = styled("div")({
  width: 198,
  padding: 16,
});

const Title = styled("div")({
  fontFamily: "Graphik Medium",
  display: "-webkit-box",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
});

const Notes = styled('div')({
  display: "-webkit-box",
  "-webkit-line-clamp": "3",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
  whiteSpace: "pre-line",
});

export const MoreInfoTooltip = ({
  label,
  notes,
  dateText,
  tooltipState,
  children,
  subCategory,
}: {
  label?: string | null;
  notes?: string | null;
  dateText: string;
  tooltipState: TooltipProps;
  children: React.FunctionComponentElement<TooltipReferenceHTMLProps>;
  subCategory?: Pick<SubCategory, "name" | "color"> | null;
}) => {
  return (
    <>
      <TooltipReference
        {...tooltipState}
        ref={children.ref}
        {...children.props}
        style={{ outline: "none" }}
      >
        {(referenceProps) => React.cloneElement(children, referenceProps)}
      </TooltipReference>
      {tooltipState.visible && (
        <Tooltip {...tooltipState}>
          <Wrap>
            {/* <Grid
              rows={["medium", "xsmall"]}
              columns={["3/4", "1/4"]}
              areas={[
                // ["main", "edit"],
                ["main", "main"],
                ["notes", "notes"],
              ]}
              gap="small"
              height="137px"
              width="198px"
              pad="16px"
            > */}
            {/* <Box> */}
            <Title>{label}</Title>
            <div>{dateText}</div>
            {/* </Box> */}
            {/* <Box gridArea="edit">Edit</Box> */}
            {notes && <Notes>{notes}</Notes>}
            {/* </Grid> */}
            {subCategory && (
              <Pill $dotColor={subCategory.color} style={{marginTop: 15}}>{subCategory.name}</Pill>
            )}
          </Wrap>
        </Tooltip>
      )}
    </>
  );
};
