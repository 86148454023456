import { useApolloClient } from "@apollo/client";
import { RouteComponentProps, WindowLocation } from "@reach/router";
import { useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { routes } from "../App";

type LocationState = {
  expired?: boolean;
};

interface Props extends RouteComponentProps {
  location?: WindowLocation<LocationState | null>;
}

export const Logout = (props: Props) => {
  const auth = useAuth();
  const client = useApolloClient();
  
  const wasExpired = props.location?.state?.expired;
  const navigate = props.navigate;

  useEffect(() => {
    auth?.logout(() => {
      client.clearStore();
      navigate?.(
        routes.login,
        wasExpired
          ? {
              state: {
                error: "You have been logged out. Please log in again.",
              },
            }
          : {}
      );
    });
  }, [auth, client, wasExpired, navigate]);

  return null;
};
