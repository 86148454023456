import { AuthLink, Header, LoginButton, Wrapper } from "./Login";
import { Field } from "./timeline/DataModal/Field";
import { Label } from "./timeline/DataModal/Label";
import { Input } from "./base/Input";
import { FormAlert } from "./base/FormAlert";
import { ButtonSize } from "./base/Button";
import { colors } from "../constants/theme";
import { ButtonAsLink } from "./base/ButtonAsLink";
import { useAuth } from "../context/AuthContext";
import { useState } from "react";
import { missingCaseError } from "../helpers/missingCaseError";
import { StaticWrapper } from "./Welcome";
import { getReadableAuthError } from "../helpers/authErrors";
import { RouteComponentProps } from "@reach/router";
import { routes } from "../App";

enum UIState {
  'default',
  'emailSubmitted',
  'newPasswordSet'
}
const defaultInfo = {
  email: "",
  password: "",
  confirmPassword: "",
  verificationCode: "",
  uiState: UIState.default,
};

export const ResetPassword = (props: RouteComponentProps) => {
  const [state, setState] = useState(defaultInfo);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const auth = useAuth();

  const handleChange =
    (field: keyof typeof defaultInfo) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({
        ...state,
        [field]: e.target.value,
      });
    };

  const handleEmailSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    auth
      ?.resetPassword({ username: state.email })
      .then((e) => {
        setIsLoading(false);

        if (e.success) {
          setError("");
          setState({
            ...state,
            uiState: UIState.emailSubmitted,
          });
        }
      })
      .catch((e) => {
        setIsLoading(false);

        if (!e.success) {
          setError(getReadableAuthError(e.error));
        }
      });
  };

  const handlePasswordSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    if (state.password === state.confirmPassword) {
      auth
        ?.newPassword({
          username: state.email,
          password: state.password,
          verificationCode: state.verificationCode,
        })
        .then((e) => {
          setIsLoading(false);

          if (e.success) {
            setError("");
            setIsLoading(false);
            setState({
              ...state,
              uiState: UIState.newPasswordSet,
            });
          }
        })
        .catch((e) => {
          setIsLoading(false);

          if (!e.success) {
            setError(getReadableAuthError(e.error));
          }
        });
    } else {
      setIsLoading(false);
      setError("Passwords do not match");
    }
  };

  const handleLogIn: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    props.navigate?.(routes.login);
  };

  const handleBackToReset: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    setError("");
    setState({
      ...state,
      uiState: UIState.default,
    });
  };

  const render = () => {
    switch (state.uiState) {
      case UIState.default:
        return (
          <Wrapper onSubmit={handleEmailSubmit}>
            <Header>Password reset</Header>
            <Field withLabel={true} vertical={true}>
              <Label>Email</Label>
              <Input
                type="email"
                value={state.email}
                onChange={handleChange("email")}
              />
            </Field>
            <FormAlert message={error} />
            <LoginButton
              size={ButtonSize.large}
              type="submit"
              color={colors.burgundy}
              isLoading={isLoading}
            >
              Send password reset email
            </LoginButton>
            <AuthLink>
              Log in instead?{" "}
              <ButtonAsLink onClick={handleLogIn}>Log in</ButtonAsLink>
            </AuthLink>
          </Wrapper>
        );
      case UIState.emailSubmitted:
        return (
          <Wrapper onSubmit={handlePasswordSubmit}>
            <Header>Password reset</Header>
            <Field withLabel={true} vertical={true}>
              <Label>Verification code</Label>
              <Input
                type="text"
                value={state.verificationCode}
                onChange={handleChange("verificationCode")}
              />
            </Field>
            <Field withLabel={true} vertical={true}>
              <Label>New password</Label>
              <Input
                type="password"
                value={state.password}
                onChange={handleChange("password")}
              />
            </Field>
            <Field withLabel={true} vertical={true}>
              <Label>Confirm new password</Label>
              <Input
                type="password"
                value={state.confirmPassword}
                onChange={handleChange("confirmPassword")}
              />
            </Field>
            <FormAlert message={error} />
            <LoginButton
              size={ButtonSize.large}
              type="submit"
              color={colors.burgundy}
              isLoading={isLoading}
            >
              Confirm new password
            </LoginButton>
            <AuthLink>
              Need to start over?{" "}
              <ButtonAsLink onClick={handleBackToReset}>
                Restart password reset
              </ButtonAsLink>
            </AuthLink>
          </Wrapper>
        );

      case UIState.newPasswordSet:
        return (
          <StaticWrapper>
            <Header>Password reset</Header>
            <AuthLink>
              Password reset successfully.{" "}
              <ButtonAsLink onClick={handleLogIn}>Log in</ButtonAsLink>
            </AuthLink>
          </StaticWrapper>
        );

      default:
        return missingCaseError(state.uiState);
    }
  }

  return render();
};
