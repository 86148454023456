import styled from "styled-components";
import { colors } from "../../constants/theme";
import React from "react";

const StyledLink = styled.a({
  color: colors.blue,
});

export const Link = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<"a">
>((props, ref) => (
  <StyledLink {...props} ref={ref} target="_blank" rel="noreferrer" />
));