import { differenceInMilliseconds, hoursToMilliseconds, startOfMinute, startOfToday, startOfTomorrow } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";

// A way to make sure if someone uses Pictal over the course of a date change, our timeline still plots correctly

type TodayContextType = Date;

const TodayContext = React.createContext<TodayContextType>(startOfToday());

const useProvideToday = () => {
  const now = useMemo(() => (new Date()), []);
  const [today, setToday] = useState(startOfToday());
  const msUntilTomorrow = useMemo(() => differenceInMilliseconds(now, startOfTomorrow()), [now]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    // Start by waiting until the next day to check again
    const timeout = setTimeout(() => {
      setToday(startOfMinute(new Date()));

      // Then check every 24 hours
      interval = setInterval(() => {
        setToday(startOfMinute(new Date()));
      }, hoursToMilliseconds(24));
    }, msUntilTomorrow + 1);

    return () => {
      clearTimeout(timeout);
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [msUntilTomorrow]);

  return today;
};

export const TodayProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const today = useProvideToday();
  return <TodayContext.Provider value={today}>{children}</TodayContext.Provider>;
};

export const useToday = () => {
  return React.useContext(TodayContext);
};
