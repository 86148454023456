import mixpanel from 'mixpanel-browser';
import { EVENTS } from './events';

mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
  debug: process.env.REACT_APP_ENV === "local",
  track_pageview: "url-with-path", // ignore query string or hash changes
  persistence: 'localStorage',
});

const identify = (analyticsId: string) => mixpanel.identify(analyticsId);
const register = (superProperties: object) => mixpanel.register(superProperties);
const reset = () => mixpanel.reset();
const track = ({
  event,
  properties,
}: {
  event: EVENTS;
  properties?: object;
}) => mixpanel.track(event, {
  ...properties,
});

export const analytics = {
  identify,
  register,
  reset,
  track,
}