import styled from "styled-components";
import { useMemo } from "react";
import {
  Tooltip as ReakitTooltip,
  TooltipReference as ReakitTooltipReference,
  useTooltipState as ReakitUseTooltipState,
  TooltipInitialState,
  TooltipReferenceHTMLProps as ReakitTooltipReferenceHTMLProps,
} from "reakit/Tooltip";
import { colors } from "../../constants/theme";

const StyledTooltip = styled(ReakitTooltip)({
  fontSize: 16,
  fontWeight: 500,
  color: colors.dark85,
  border: `1px solid ${colors.light110}`,
  lineHeight: `24px`,
  borderRadius: 3,
  boxShadow: "0px 2px 10px rgb(15 40 63 / 20%)",
  background: colors.white,
});

export type TooltipProps = React.ComponentPropsWithoutRef<typeof ReakitTooltip>;
export const Tooltip = ({ children, ...tooltip }: TooltipProps) => {
  return <StyledTooltip {...tooltip}>{children}</StyledTooltip>;
};

export const useTooltipState = (initialState: TooltipInitialState) => {
  const tooltipState = ReakitUseTooltipState(initialState);

  return useMemo(
    () => ({
      ...tooltipState,
    }),
    [tooltipState]
  );
};

export const TooltipReference = ReakitTooltipReference;
export type TooltipReferenceHTMLProps = ReakitTooltipReferenceHTMLProps;
