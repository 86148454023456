import React, { useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Checkbox, useCheckboxState } from "reakit/Checkbox";

import { colors } from "../constants/theme";
import { useAuth } from "../context/AuthContext";
import { ButtonSize } from "./base/Button";
import { Field } from "./timeline/DataModal/Field";
import { Label } from "./timeline/DataModal/Label";
import { FormAlert } from "./base/FormAlert";
import { AuthLink, Header, LoginButton, Wrapper } from "./Login";
import { ButtonAsLink } from "./base/ButtonAsLink";
import { Input } from "./base/Input";
import { getReadableAuthError } from "../helpers/authErrors";
import { HelpText } from "./base/HelpText";
import { CustomInput } from "./timeline/DataModal/DatePicker";
import { range } from "d3-array";
import { getMonth, getYear } from "date-fns";
import styled from "styled-components";
import { Link } from "./base/Link";
import { RouteComponentProps } from "@reach/router";
import { routes } from "../App";


const termsOfUseUrl =
  "https://www.pictalhealth.com/s/Terms_of_Use-PictalHealth_022024.pdf";
const privacyPolicyUrl =
  "https://static1.squarespace.com/static/5b0f678e3c3a5315e6f7ae07/t/64cea22977897518ccc6b6cb/1691263529681/Privacy_Policy-PictalHealth_080523.pdf";
const defaultInfo: {
  name: string;
  email: string;
  password: string;
  signup_code: string;
  dateOfBirth: Date | null;
} = {
  name: "",
  email: "",
  password: "",
  signup_code: "",
  dateOfBirth: null,
};

const Terms = styled.div({
  display: 'flex',
  color: colors.select,
  alignItems: 'start',
  gap: 8,
});

export const SignUp = (props: RouteComponentProps) => {
  const [state, setState] = useState(defaultInfo);
  const termsCheckbox = useCheckboxState({ state: false });
  const [error, setError] = useState("");
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const today = useMemo(() => new Date(), []);

    const years = range(1900, getYear(new Date()) + 1, 1);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

  const handleChange =
    (field: keyof typeof defaultInfo) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState({
        ...state,
        [field]: e.target.value,
      });
    };

  const handleDateOfBirthChange = (dateOfBirth: Date) =>
    setState({
      ...state,
      dateOfBirth,
    });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!termsCheckbox.state) {
      setError("Please check the box to accept Terms.");
      return;
    }
    setError("");
    setIsLoading(true);

    auth
      ?.signup({
        name: state.name,
        email: state.email,
        password: state.password,
        signup_code: state.signup_code,
        dateOfBirth: state.dateOfBirth,
      })
      .then(() => props.navigate?.(routes.confirmEmail))
      .catch((e) => {
        setIsLoading(false);

        if (!e.success) {
          setError(getReadableAuthError(e.error));
        }
      });
  };

  const handleLogin: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    props.navigate?.(routes.login);
  };

  return (
    <Wrapper onSubmit={handleSubmit}>
      <Header>Sign Up</Header>
      <Field withLabel={true} vertical={true}>
        <Label>Name</Label>
        <Input type="name" value={state.name} onChange={handleChange("name")} />
      </Field>{" "}
      <Field withLabel={true} vertical={true}>
        <Label>Email</Label>
        <Input
          type="email"
          value={state.email}
          onChange={handleChange("email")}
        />
      </Field>
      <Field withLabel={true} vertical={true}>
        <Label>Password</Label>
        <Input
          type="password"
          value={state.password}
          onChange={handleChange("password")}
        />
        <HelpText>
          At least 8 characters and at least 1 number, special character,
          upper-case letter, and lower-case letter.
        </HelpText>
      </Field>
      <Field withLabel={true} vertical={true}>
        <Label>Birthdate</Label>
        <HelpText>So we can set up your timeline.</HelpText>
        <ReactDatePicker
          selected={state.dateOfBirth}
          onChange={handleDateOfBirthChange}
          customInput={<CustomInput />}
          placeholderText={"MM/DD/YYYY"}
          maxDate={today}
          disabledKeyboardNavigation
          showMonthDropdown
          showYearDropdown
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                type="button"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                {"<"}
              </button>
              <select
                value={getYear(date)}
                onChange={({ target: { value } }) =>
                  changeYear(parseInt(value))
                }
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button
                type="button"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                {">"}
              </button>
            </div>
          )}
        />
      </Field>
      <Field withLabel={true} vertical={true}>
        <Label>Signup Code</Label>
        <HelpText>
          It’s in your welcome email. Don’t have a membership?{" "}
          <Link href="https://www.pictalhealth.com/join">Join now</Link>.
        </HelpText>
        <Input
          type="text"
          value={state.signup_code}
          onChange={handleChange("signup_code")}
        />
      </Field>
      <Field withLabel={true} vertical={true}>
        <Label>Terms of Use</Label>
        <Terms>
          <Checkbox {...termsCheckbox} />
          <div>
            I agree to Pictal Health's
            <br />
            <Link href={termsOfUseUrl}>Terms of Use</Link> and{" "}
            <Link href={privacyPolicyUrl}>Privacy Policy</Link>
          </div>
        </Terms>
      </Field>
      <FormAlert message={error} />
      <LoginButton
        size={ButtonSize.large}
        type="submit"
        color={colors.burgundy}
        isLoading={isLoading}
      >
        Sign Up
      </LoginButton>
      <AuthLink>
        Or <ButtonAsLink onClick={handleLogin}>Log In</ButtonAsLink>
      </AuthLink>
    </Wrapper>
  );
};
