import React, { useCallback } from "react";
import { motion } from "framer-motion";
import { isSameDay } from "date-fns";
import { BarRounded } from "@visx/shape";
import { scaleLinear } from "@visx/scale";

import { getDateEnd, getDateStart, TimeScale } from "../helpers";
import { Labels, Alignment, getDatesDisplay } from "../Labels";
import { ChartCategory } from "../../../data/graphql/generated/graphql";
import { useDataModal } from "../DataModalContext";
import { ChartDataDisplay, ColorConfig, replaceCategoryColors, getTransparentColor } from "../config";
import { MoreInfoTooltip } from "../MoreInfoTooltip";
import { useTooltipState } from "../../base/Tooltip";
import { Chart, useEndX, useSortedChartData, useStartX, useYMax } from "./helpers";
import { analytics } from "../../../analytics";
import { EVENTS } from "../../../analytics/events";

export const BUBBLE_VALUE_MIN = 1;
export const BUBBLE_VALUE_MAX = 10;
export const getBubbleValue = (bubbleValue?: null | number) => bubbleValue || 0;

export enum ChartHeights {
  NORMAL = "NORMAL",
  CONDENSED = "CONDENSED",
}

const strokeWidth = 1;

export const getBubbleRadius = (value: Number) => {
  const max = chartHeightOptions[ChartHeights.NORMAL].radius.max;
  const min = chartHeightOptions[ChartHeights.NORMAL].radius.min;

  const scale = scaleLinear({
    domain: [BUBBLE_VALUE_MIN, BUBBLE_VALUE_MAX],
    range: [min, max]
  });

  return scale(value);
};
const getChartDataRadius = (bd: ChartDataDisplay, chartHeightType: ChartHeights) => {
  if (chartHeightType === ChartHeights.CONDENSED) {
    return chartHeightOptions[ChartHeights.CONDENSED].radius;
  }

  return getBubbleRadius(getBubbleValue(bd.value));
};

export const Bubble = React.memo(
  ({
    bd,
    getX,
    getEndX,
    colors,
    yMax,
    radius,
    isSpan,
  }: {
    bd: ChartDataDisplay;
    getX: (bd: ChartDataDisplay) => number;
    getEndX: (cd: ChartDataDisplay) => number;
    colors: ColorConfig;
    yMax: number;
    radius: number;
    isSpan: boolean;
  }) => {
    const { showModal, setModalData } = useDataModal();
    const tooltipState = useTooltipState({
      placement: "bottom-start",
    });
    const cy = yMax - radius;
    const cx = getX(bd);
    const diameter = radius * 2;
    const barWidth = Math.max(getEndX(bd) - cx + (isSpan ? diameter : 0), 1);

    // If the span would be squished, use a circle instead to preserve the radius
    const useSpan = isSpan && barWidth > diameter;

    return (
      <MoreInfoTooltip
        key={`${bd.id}-tooltip`}
        label={bd.label}
        dateText={getDatesDisplay(bd)}
        notes={bd.notes}
        tooltipState={tooltipState}
        subCategory={bd.subCategory}
      >
        {useSpan ? (
          <g>
            <BarRounded
              key={`${bd.id}-circle-span`}
              height={radius * 2}
              radius={radius}
              width={barWidth}
              // BarRounded needs to be offset by the radius so it's aligned
              // with where a circle starts
              x={cx - radius}
              y={cy - radius}
              all={true}
              strokeWidth={strokeWidth}
              fill={getTransparentColor(colors.fill)}
              stroke={getTransparentColor(colors.stroke)}
              onClick={() => {
                setModalData(bd);
                showModal();
                analytics.track({
                  event: EVENTS.CLICK_TIMELINE_EVENT,
                  properties: {
                    chartType: "barRounded",
                    chartCategory: bd.chartCategory,
                  },
                });
              }}
              onMouseEnter={tooltipState.show}
              onMouseLeave={tooltipState.hide}
            />
          </g>
        ) : (
          <motion.circle
            key={`${bd.id}-circle`}
            cx={cx}
            cy={cy}
            r={radius}
            strokeWidth={strokeWidth}
            fill={getTransparentColor(colors.fill)}
            stroke={getTransparentColor(colors.stroke)}
            // animate={{ cx }}
            // transition={{ duration: 0.3 }}
            onClick={() => {
              setModalData(bd);
              showModal();
              analytics.track({
                event: EVENTS.CLICK_TIMELINE_EVENT,
                properties: {
                  chartType: "circle",
                  chartCategory: bd.chartCategory,
                },
              });
            }}
            onMouseEnter={tooltipState.show}
            onMouseLeave={tooltipState.hide}
          />
        )}
      </MoreInfoTooltip>
    );
  }
);

export const chartHeightOptions = {
  [ChartHeights.NORMAL]: {
    padding: {
      open: 30,
    },
    radius: {
      max: 50,
      min: 17.5,
    },
  },
  [ChartHeights.CONDENSED]: {
    padding: {
      open: 20,
    },
    radius: 17.5,
  },
};

const getBubbleColors = ({
  bubbleData,
  categoryColors,
}: {
  bubbleData: ChartDataDisplay;
  categoryColors: ColorConfig;
}): ColorConfig =>
  bubbleData.subCategory ? replaceCategoryColors({ color: bubbleData.subCategory.color, categoryColors }) : categoryColors;

type ChartBubbleProps = {
  width: number;
  bubbleData: ChartDataDisplay[];
  colors: ColorConfig;
  timeScale: TimeScale;
  chartHeightType?: ChartHeights;
  category: ChartCategory;
  canUseSpan?: boolean;
};

export const ChartBubble = ({
  width,
  bubbleData,
  colors,
  timeScale,
  chartHeightType = ChartHeights.NORMAL,
  category,
  canUseSpan = false,
}: ChartBubbleProps) => {
  const getX = useStartX({ timeScale });
  const getEndX = useEndX({ timeScale, canUseSpan });

  const getIsBubbleSpan = useCallback(
    ({
      chartData,
      canUseSpan,
    }: {
      chartData: ChartDataDisplay;
      canUseSpan: boolean;
    }) => {
      if (!canUseSpan) {
        return false;
      }

      const dateEnd = getDateEnd(chartData);
      const dateStart = getDateStart(chartData);

      return !!dateEnd && !isSameDay(dateStart, dateEnd);
    },
    []
  );

  const {yMax, padding} = useYMax({ chartHeightType });

  // SVGs position the stroke centered on the radius
  // Half of the strokewidth on the top and bottom needs
  // to be accounted for aka 1 full strokeWidth
  const chartHeight = yMax + strokeWidth + padding;

  const sortedBubbleData = useSortedChartData({
    chartData: bubbleData,
    getX: getX,
  });

  if (!bubbleData.length) {
    return null;
  }

  return (
    <>
      <Chart width={width} height={chartHeight}>
        <g>
          {bubbleData.map((bd, i) => (
            <Bubble
              key={bd.id}
              bd={bd}
              getX={getX}
              getEndX={getEndX}
              colors={getBubbleColors({ categoryColors: colors, bubbleData: bd })}
              yMax={yMax}
              radius={getChartDataRadius(bd, chartHeightType)}
              isSpan={getIsBubbleSpan({ chartData: bd, canUseSpan })}
            />
          ))}
        </g>
      </Chart>
      <Labels
        chartWidth={width}
        data={sortedBubbleData}
        getX={getX}
        alignment={Alignment.bottom}
        color={colors.fill}
      />
    </>
  );
};
