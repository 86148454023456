import { Menu, MenuItem, MenuSeparator } from "reakit/Menu";
import styled from "styled-components";

import { colors } from "../../constants/theme";
import { sharedOptionStyles } from "./Select";

const borderRadius = 3;

export const DropdownMenu = styled(Menu)({
  boxShadow: "0px 2px 10px rgb(15 40 63 / 20%)",
  border: `1px solid ${colors.light110}`,
  borderRadius,
  maxHeight: "90vh",
  overflow: "auto",
  backgroundColor: colors.light90,
});

export const DropdownMenuItem = styled(MenuItem)({
  ...sharedOptionStyles,
  border: "none",
  outline: "none",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  width: "100%",
  "&:hover": {
    background: colors.light,
  },
  color: colors.dark85,
  textDecoration: "none",
  fontFamily: "Graphik Regular",
  backgroundColor: colors.light90,
});

export const DropdownHeader = styled("div")({
  backgroundColor: colors.light,
  color: colors.dark85,
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "21px",
  padding: "0 20px",
  height: 45,
  display: "flex",
  alignItems: "center",
});

export const DropdownMenuSeparator = styled(MenuSeparator)({
  margin: '0 17px',
  height: 1,
  color: colors.light110,
  background: colors.light110,
  fontSize: 0,
  border: 0,
});
