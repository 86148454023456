import { transparentize } from "polished";
import styled from "styled-components";
import { colors } from "../../constants/theme";
import { useDataModal } from "./DataModalContext";
import { Popover, PopoverDisclosureProps } from "../base/Popover";
import { ChartDataDisplay } from "./config";
import { usePopoverState } from "reakit";
import { analytics } from "../../analytics";
import { EVENTS } from "../../analytics/events";

const Container = styled('div')({
  width: 198,
  padding: '9px 0',
});

const Title = styled("div")({
  flexShrink: 0,
  fontWeight: 600,
  display: "-webkit-box",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
});

const Dates = styled("div")({
  flexShrink: 0,
});

const Item = styled('div')({
  padding: "8px 15px",
  minHeight: 55,
  cursor: 'pointer',
  ":hover": {
    backgroundColor: transparentize(0.9, colors.burgundy),
  },
});

export const MultipleMenu = ({
  dataPoints,
  getDisplayInfo,
  children,
}: {
  dataPoints: ChartDataDisplay[];
  getDisplayInfo: (dataPoint: ChartDataDisplay) => {
    label: string;
    displayDates: string;
  };
  children: PopoverDisclosureProps;
}) => {
  const { setModalData, showModal } = useDataModal();
  const popover = usePopoverState({ placement: 'bottom-start' });

  return (
    <>
      <Popover disclosure={children} popover={popover}>
        <Container>
          {dataPoints.map((dataPoint) => {
            const { label, displayDates } = getDisplayInfo(dataPoint);

            return (
              <Item
                onClick={() => {
                  setModalData(dataPoint);
                  showModal();
                  analytics.track({
                    event:
                      EVENTS.CLICK_TIMELINE_EVENT_MULTIPLE_MENU,
                    properties: {
                      chartProperty: dataPoint.chartCategory,
                      multipleMenuLength: dataPoints.length
                    }
                  });
                }}
                key={`${label}-${displayDates}`}
              >
                <Title>{label}</Title>
                <Dates>{displayDates}</Dates>
              </Item>
            );
          })}
        </Container>
      </Popover>
    </>
  );
};
