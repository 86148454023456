import { Button as ReakitButton } from "reakit";
import styled from "styled-components";
import { colors } from "../../constants/theme";

export const neutralButtonStyles: React.CSSProperties = {
  border: "none",
  outline: "none",
  cursor: "pointer",
  textAlign: "center",
  padding: "5px 10px",
  background: "transparent",
  overflow: "hidden",
};
export const NeutralButton = styled(ReakitButton)<{ color?: string }>(({ color }) => ({
  ...neutralButtonStyles,
  color: color ? color : colors.light90,
}));
