import { Button as ReakitButton } from "reakit";
import styled from "styled-components";
import { colors } from "../../constants/theme";
import { darken } from "polished";

type OutlineButtonColors = {
  color: string;
  backgroundColor: string;
  borderColor: string;
};

export const OUTLINE_BUTTON_LEFT_PADDING = 10;

export const OutlineButton = styled(ReakitButton)<{
  $buttonColors?: OutlineButtonColors;
}>(({ $buttonColors }) => ({
  borderColor: $buttonColors?.borderColor || colors.buttonBorder,
  borderWidth: 1,
  borderStyle: "solid",
  borderRadius: 3,
  cursor: "pointer",
  textAlign: "center",
  paddingBlock: 5,
  paddingLeft: OUTLINE_BUTTON_LEFT_PADDING,
  paddingRight: 14,
  backgroundColor: $buttonColors?.backgroundColor || colors.dark,
  color: $buttonColors?.color || colors.white,
  height: 40,
  fontFamily: "Graphik Regular",
  fontSize: 16,
  display: "flex",
  alignItems: "center",
  gap: 6,
  overflow: 'hidden',
  "&:hover, &:focus, &:active": {
    backgroundColor: darken(0.02, $buttonColors?.backgroundColor || colors.dark),
  },
}));
