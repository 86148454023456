import React, { Dispatch, SetStateAction } from "react";
import { ChartDataAccessType, User } from "../data/graphql/generated/graphql";
import { missingCaseError } from "../helpers/missingCaseError";

export const canEditUser = (myChartDataAccess: MyChartDataAccessContextInterface) => {
  if (!myChartDataAccess.currentlyViewingUser) {
    return false;
  }
  const currentlyViewingUserAccessType = myChartDataAccess.myChartDataAccess.find((mcda) => mcda.about.id === myChartDataAccess.currentlyViewingUser?.id);

  if (!currentlyViewingUserAccessType) {
    return false;
  }
  switch (currentlyViewingUserAccessType.accessType) {
    case ChartDataAccessType.SuperAdmin:
    case ChartDataAccessType.Owner:
    case ChartDataAccessType.Write:
      return true;
    case ChartDataAccessType.Read:
      return false;

    default:
      return missingCaseError(currentlyViewingUserAccessType.accessType);
  }
};

export type MyChartDataAccessUser = Pick<User, "id" | "displayName" | "dateOfBirth">;
type MyChartDataAccess = {
  about: MyChartDataAccessUser;
  accessType: ChartDataAccessType;
};

interface MyChartDataAccessContextInterface {
  myChartDataAccess: MyChartDataAccess[];
  currentlyViewingUser: MyChartDataAccessUser | null;
  setCurrentlyViewingUserId: Dispatch<
    SetStateAction<string | null>
  >;
}

export const MyChartDataAccessContext =
  React.createContext<MyChartDataAccessContextInterface>({
    myChartDataAccess: [],
    currentlyViewingUser: null,
    setCurrentlyViewingUserId: () => {},
  });

export const ChartDataAccessTypeDisplayText: {[key in ChartDataAccessType]: string } = {
  [ChartDataAccessType.SuperAdmin]: 'Super Admin',
  [ChartDataAccessType.Owner]: 'Owner',
  [ChartDataAccessType.Write]: 'Editor',
  [ChartDataAccessType.Read]: 'Viewer',
}