import { useCallback, useMemo } from "react";
import { ChartDataDisplay } from "../config";
import { TimeScale, getDateEnd, getDateStart } from "../helpers";
import styled from "styled-components";
import { motion } from "framer-motion";
import { ChartHeights, chartHeightOptions } from "./ChartBubble";

export const useStartX = ({ timeScale }: { timeScale: TimeScale}) =>
  useCallback(
    (cd: ChartDataDisplay) => timeScale(getDateStart(cd)) || 0,
    [timeScale]
  );

export const useEndX = ({
  canUseSpan,
  timeScale,
}: {
  canUseSpan: boolean;
  timeScale: TimeScale;
}) =>
  useCallback(
    (cd: ChartDataDisplay) => {
      const dateEnd = getDateEnd(cd);
      const dateStart = getDateStart(cd);
      return (
        (canUseSpan && dateEnd && timeScale(dateEnd)) || timeScale(dateStart) || 0
      );
    },
    [timeScale, canUseSpan]
  );

export const useSortedChartData = ({
  chartData,
  getX,
}: {
  chartData: ChartDataDisplay[];
  getX: (bd: ChartDataDisplay) => number;
}) =>
  useMemo(
    () =>
      chartData.sort((a, b) => {
        const aLabelX = getX(a);
        const bLabelX = getX(b);

        return aLabelX - bLabelX;
      }),
    [chartData, getX]
  );

export const useYMax = ({ chartHeightType }: { chartHeightType: ChartHeights; }) =>
  useMemo(() => {
    const chartHeights = chartHeightOptions[chartHeightType];

    const maxRadius =
      typeof chartHeights.radius === "number"
        ? chartHeights.radius
        : chartHeights.radius.max;

    return {
      yMax: maxRadius * 2 + chartHeights.padding.open,
      padding: 0,
    };
  }, [
    chartHeightType,
  ]);

const ChartWrapper = styled(motion.svg)({
  display: "block",
});

export const Chart = ({ width, height, children }: { width: number; height: number; children: React.ReactNode }) => (
  <ChartWrapper
    width={width}
    height={height}
    // Need a way to not run this when downloading...
    // animate={{ height: chartHeight }}
    // transition={{ duration: 0.2 }}
    className="chart"
  >{children}</ChartWrapper>
);