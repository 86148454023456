import styled from "styled-components";
import { colors } from "../../constants/theme";
import { Dialog, DialogBackdrop, useDialogState, DialogStateReturn, DialogDisclosure } from 'reakit/Dialog';
import { ButtonAsLink } from "./ButtonAsLink";
import { Icon } from "./Icon";

export type ModalStateProps = React.ComponentPropsWithoutRef<typeof Dialog>;

type ModalProps = ModalStateProps & {
  children: React.ReactNode;
  maxWidth?: number;
  isDismissible?: boolean;
};

const StyledModal = styled(Dialog).withConfig({
  shouldForwardProp: (prop: any) => prop !== "maxWidth",
})<{ maxWidth?: number }>(({ maxWidth }) => ({
  backgroundColor: colors.light90,
  borderRadius: 4,
  boxSizing: "border-box",
  maxWidth: maxWidth || 645,
  width: "calc(100% - 40px)",
  padding: "0 24px",
  zIndex: 100,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  animation: "0.2s ease-in-out 0s 1 normal forwards running hWAzxx",
}));

const StyledBackdrop = styled(DialogBackdrop)({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 99,
});

const StyledModalHeader = styled("div")({
    borderBottom: `1px solid ${colors.light}`,
    padding: "20px 0",
    display: "flex",
    alignContent: "flex-start",
    alignSelf: "flex-start",
    justifyContent: "space-between",
});

export const ModalHeading = styled("h4")({
  color: colors.dark85,
  fontSize: 24,
  fontFamily: "Graphik Medium",
  fontWeight: 500,
  lineHeight: "37px",
});

export const ModalSubheading = styled("h5")({
  color: colors.dark85,
  fontSize: 18,
  fontFamily: "Graphik Medium",
  fontWeight: 500,
  lineHeight: "21px",
});

const StyledModalBody = styled('div')({
  paddingTop: 12,
  maxHeight: '70vh',
  overflowY: 'auto',
});

const StyledModalFooter = styled("div")({
  borderTop: `1px solid ${colors.light}`,
  padding: "20px 0",
  display: "flex",
  gap: 10,
  alignContent: "flex-start",
  alignSelf: "flex-start",
  justifyContent: "flex-start",
});

export const Modal = ({
  children,
  maxWidth,
  isDismissible = false,
  ...modalState
}: ModalProps) => {
  return (
    <StyledBackdrop {...modalState}>
      <StyledModal
        hideOnClickOutside={isDismissible}
        hideOnEsc={isDismissible}
        {...modalState}
        maxWidth={maxWidth}
      >
        {children}
      </StyledModal>
    </StyledBackdrop>
  );
};

type ModalBodyProps = ModalStateProps & {
  children: React.ReactNode;
};

export const ModalBody = ({
  children,
  ...modalState
}: ModalBodyProps) => (
  <StyledModalBody {...modalState}>
    {children}
  </StyledModalBody>
);

type ModalHeaderProps = ModalStateProps & {
  children: React.ReactNode;
  isDismissible?: boolean;
};

export const ModalHeader = ({
  children,
  isDismissible = true,
  ...modalState
}: ModalHeaderProps) => (
  <StyledModalHeader>
    {children}
    {isDismissible && (
      <Icon
        name="close"
        style={{
          cursor: "pointer",
          color: colors.buttonColor,
          fontSize: 35,
        }}
        onClick={modalState.hide}
      />
    )}
  </StyledModalHeader>
);

type ModalFooterProps = ModalStateProps & {
  children: React.ReactNode;
};

export const ModalFooter = ({ children, ...modalState }: ModalFooterProps) => (
  <StyledModalFooter {...modalState}>{children}</StyledModalFooter>
);

export const StyledModalFooterButtonAndLink = styled('div')({
  display: 'flex',
  gap: 34,
  [ButtonAsLink]: {
    textDecoration: 'none',
    color: colors.burgundy,
  }
});

export const ModalFooterButtonAndLink = ({
  children,
}: {
  children: React.ReactNode;
}) => <StyledModalFooter>{children}</StyledModalFooter>;

export const useModalState = useDialogState;
export const ModalDisclosure = DialogDisclosure;
export type ModalStateReturn = DialogStateReturn;
