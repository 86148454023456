import React from 'react';
import { Input } from '../../base/Input';
import styled from 'styled-components';
import { colors } from '../../../constants/theme';

const DateLabel = styled('label')({
  fontSize: 12,
  fontFamily: "Graphik Regular",
  padding: 0,
  textAlign: 'center',
  flexBasis: 50,
});

const DateLabelYear = styled(DateLabel)({
  flexBasis: 65,
});

const DateInput = styled(Input)(() => ({
  padding: "0 8px",
  textAlign: "center" as const,
  marginTop: 5,
}));

const DateError = styled('p')({
  marginTop: 3,
  marginBottom: 0,
  color: colors.red,
  fontSize: 13,
});

const DateWrapper = styled.div(({ disabled }: { disabled: boolean }) => ({
  display: "flex",
  gap: 5,
  alignItems: "center",
  color: disabled ? colors.light110 : colors.dark85,
}));

export type DateInputValues = {
  month: string;
  day: string;
  year: string;
}
type DateInputValuesKeys = keyof DateInputValues;
type DateInputRequired = {
  [k in DateInputValuesKeys]?: boolean;
};

export const DateInputs = ({
  displayValues,
  dateInputRequired,
  onChange,
  error,
  disabled,
}: {
  displayValues: DateInputValues;
  dateInputRequired?: DateInputRequired;
  onChange: (newValues: DateInputValues) => void;
  error?: string;
  disabled?: boolean;
}) => {
  const handleChange =
    (field: DateInputValuesKeys) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;

      // Only if it's empty or a number
      if (newValue.length === 0 || /^\d+$/.test(newValue)) {
        onChange({
          ...displayValues,
          [field]: newValue,
        });
      }
    };

  const isDisabled = !!disabled;

  return (
    <div>
      <DateWrapper disabled={isDisabled}>
        <DateLabelYear>
          {dateInputRequired && dateInputRequired.year && '*'}Year
          <DateInput
            placeholder="YYYY"
            maxLength={4}
            value={displayValues.year}
            onChange={handleChange("year")}
            $isInvalid={!!error}
            disabled={isDisabled}
            tabIndex={0}
          />
        </DateLabelYear>
        <DateLabel>
          {dateInputRequired && dateInputRequired.month && '*'}Month
          <DateInput
            placeholder="MM"
            maxLength={2}
            value={displayValues.month}
            onChange={handleChange("month")}
            $isInvalid={!!error}
            disabled={isDisabled}
            tabIndex={0}
          />
        </DateLabel>
        <DateLabel>
          {dateInputRequired && dateInputRequired.day && '*'}Day
          <DateInput
            placeholder="DD"
            maxLength={2}
            value={displayValues.day}
            onChange={handleChange("day")}
            $isInvalid={!!error}
            disabled={isDisabled}
            tabIndex={0}
          />
        </DateLabel>
      </DateWrapper>
      <DateError>{error || <>&nbsp;</>}</DateError>
    </div>
  );
};
