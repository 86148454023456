import styled from "styled-components";
import { useEffect, useRef } from 'react';
import { DisclosureStateReturn, useDisclosureState } from "reakit";
import { NeutralButton } from "../../base/NeutralButton";
import { colors } from "../../../constants/theme";
import { FILTER_PANE_PADDING, FilterSection } from "./FilterSection";
import { FilteredDisplayData, useDisplayData } from "../Timeline/DisplayDataContext";
import { Separator } from "../../base/Separator";
import { StyledFilterToggleButton } from "./FilterToggleButton";
import { analytics } from "../../../analytics";
import { EVENTS } from "../../../analytics/events";
import { Icon } from "../../base/Icon";

export const FILTER_PANE_WIDTH = 400;

const Pane = styled.div({
  background: colors.light,
  border: `1px solid ${colors.illustrationGrey}`,
  zIndex: 2,
  minHeight: '100%',
  boxSizing: 'border-box',
});

const StickyHeader = styled("div")({
  zIndex: 1,
  background: colors.light,
  position: "sticky",
  top: 0,
  padding: FILTER_PANE_PADDING,
  borderBottom: `1px solid ${colors.illustrationGrey}`,
  // boxShadow: `0px 2px 6px rgba(15, 40, 63, 0.101449)`,
});

const Header = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 12,
  fontWeight: 500,
  fontSize: 13,
  lineHeight: "14px",
  marginBottom: 20,
  color: colors.dark85,
});

const CloseButton = styled(StyledFilterToggleButton)({
  position: 'relative',
  top: 'auto',
  left: 'auto',
  paddingLeft: 0,
  paddingRight: 0,
});

const FilterSectionWrap = styled("div")({
  padding: FILTER_PANE_PADDING,
});

type FilterSectionWrapperProps = {
  fdd: FilteredDisplayData;
  setRef: (disclosure: DisclosureStateReturn) => DisclosureStateReturn;
};

const FilterSectionWrapper = ({
  fdd,
  setRef,
}: FilterSectionWrapperProps) => {
  const disclosure = useDisclosureState({ visible: false });
  useEffect(() => {
    setRef(disclosure);
  }, [setRef, disclosure]);

  return (
    <FilterSection
      displayData={fdd}
      key={fdd.config.text.plural}
      disclosure={disclosure}
    />
  );
};

type FilterPaneProps = {
  onClose: () => void;
  showNewUserUI: boolean;
};

const AllSectionsButtons = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const FilterPane = ({
  onClose,
  showNewUserUI,
}: FilterPaneProps) => {
  const { filteredDisplayData, hiddenDataCount, visibleDataCount } = useDisplayData();
  const handleOpenAll = () => {
    Object.values(disclosureRefs.current).forEach(
      (disclosure) => disclosure.show()
    );

    analytics.track({
      event: EVENTS.CLICK_FILTER_PANE_OPEN_ALL_CATEGORIES,
    });
  };

  const handleCloseAll = () => {
    Object.values(disclosureRefs.current).forEach((disclosure) =>
      disclosure.hide()
    );

    analytics.track({
      event: EVENTS.CLICK_FILTER_PANE_CLOSE_ALL_CATEGORIES,
    });
  };

  // Use refs to keep track of disclosures of children so this component can control them
  const disclosureRefs = useRef<{ [key: number]: DisclosureStateReturn }>({});

  useEffect(() => {
    if (showNewUserUI) {
      handleOpenAll();
    }
  }, [showNewUserUI]);

  const showingText = `${visibleDataCount} event${visibleDataCount === 1 ? "" : "s"} showing`;
  const hiddenText = `${hiddenDataCount} hidden`;

  return (
    <Pane>
      <StickyHeader>
        <Header>
          <span>
            {showingText}{" "}
            <Icon name="visibility_off" style={{ verticalAlign: "middle" }} />{" "}
            {hiddenText}
          </span>
          <CloseButton
            onClick={onClose}
            $buttonColors={{
              color: colors.dark,
              backgroundColor: colors.white,
              borderColor: colors.illustrationGrey,
            }}
          >
            <Icon name="chevron_left" style={{ fontSize: 36 }} />
          </CloseButton>
        </Header>
        <AllSectionsButtons>
          <NeutralButton color={colors.dark85} onClick={handleOpenAll}>
            Open all
          </NeutralButton>
          <Separator orientation="vertical" />
          <NeutralButton color={colors.dark85} onClick={handleCloseAll}>
            Close all
          </NeutralButton>
        </AllSectionsButtons>
      </StickyHeader>
      <FilterSectionWrap>
        {filteredDisplayData.map((fdd, index) => (
          <FilterSectionWrapper
            key={fdd.config.category}
            fdd={fdd}
            setRef={(disclosure: DisclosureStateReturn) =>
              (disclosureRefs.current[index] = disclosure)
            }
          />
        ))}
      </FilterSectionWrap>
    </Pane>
  );
};
