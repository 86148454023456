import { Input as ReakitInput } from "reakit/Input";
import styled, { CSSObject } from "styled-components";
import { colors } from "../../constants/theme";

export enum InputSize {
  "large",
  "normal",
}

export const textStyles: CSSObject = {
  borderRadius: 4,
  background: colors?.white,
  border: `1px solid ${colors?.light110}`,
  padding: "0 16px",
  outline: "none",
  fontFamily: "Graphik Regular",
  fontWeight: 400,
  color: colors?.dark85,
  "::placeholder": {
    color: colors?.dark50,
  },
};

export const Input = styled(ReakitInput)<{
  size?: InputSize;
  disabled?: boolean;
  $isInvalid?: boolean;
}>(({ size = InputSize.normal, disabled = false, $isInvalid = false }) => ({
  ...textStyles,
  ...(size === InputSize.normal
    ? {
        height: 42,
        fontSize: 18,
        lineHeight: "32px",
      }
    : {
        height: 56,
        fontSize: 26,
        lineHeight: "37px",
      }),
  width: "100%",
  boxSizing: "border-box",
  ...(disabled && {
    color: colors.light110,
    "::placeholder": {
      color: colors.light110,
    },
    ":active, :focus": {
      borderColor: colors.light110,
    },
  }),
  ...($isInvalid && {
    borderColor: colors.red,
  }),
  ...(!$isInvalid && {
    ":active, :focus": {
      borderColor: colors.blue,
    },
  }),
  ":read-only": {
    border: "none",
    background: colors.light90,
  },
}));
