import { useMedia } from "react-use";
import { Field } from "./Field";
import { Label } from "./Label";
import { SliderInput } from "../SliderInput";
import { ChartConfigType, replaceCategoryColors } from "../config";
import { ChartCategory, SubCategory } from "../../../data/graphql/generated/graphql";
import styled from "styled-components";
import { Icon } from "../../base/Icon";

type DataModalSliderInputProps = {
  value: number;
  category: ChartConfigType;
  subCategory?: Pick<SubCategory, 'color'> | null;
  onChange: (newValue: number) => void;
  readOnly: boolean;
};

type CategoryStrings = {
  text: string;
};

const getStrings = (category: ChartConfigType): CategoryStrings | undefined => {
  if (category.category === ChartCategory.LifeEvents) {
    return {
      text: "How stressful or how big of a deal was it?",
    };
  }

  if (category.category === ChartCategory.InjuriesIllnesses || category.category === ChartCategory.Symptoms) {
    return {
      text: "How bad or serious was it?",
    };
  }
};

const Paragraph = styled.p({
  marginTop: 0,
});

const SliderWrapper = styled.div({
  width: '100%',
});

export const DataModalSliderInput = ({
  value,
  category,
  subCategory,
  onChange,
  readOnly,
}: DataModalSliderInputProps) => {
  const strings = getStrings(category);
  const color = subCategory ? replaceCategoryColors({ categoryColors: category.colors, color: subCategory.color }) : category.colors;
  const isMobile = useMedia("(max-width: 500px)");

  if (!strings) {
    return null;
  }

  return (
    <Field withLabel={true}>
      {!isMobile && (
        <Label>
          <Icon name="show_chart" />
        </Label>
      )}
      <SliderWrapper>
        <Paragraph>{strings.text}</Paragraph>
        <SliderInput
          value={value}
          onChange={onChange}
          color={color}
          readOnly={readOnly}
        />
      </SliderWrapper>
    </Field>
  );
};
