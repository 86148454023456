import { MenuButton, useMenuState } from "reakit/Menu";

import {
  chartCategoryConfig,
  CategorySelectedOption,
} from "./DataModal";
import {
  ChartCategory,
} from "../../data/graphql/generated/graphql";
import { DropdownHeader, DropdownMenu, DropdownMenuItem } from "../base/DropdownMenu";
import { OutlineButton } from "../base/OutlineButton";
import {
  useDataModal
} from './DataModalContext';
import { analytics } from "../../analytics";
import { EVENTS } from "../../analytics/events";
import { Icon } from "../base/Icon";

export const AddData = () => {
  const { setModalData, showModal } = useDataModal();
  const setSelectedCategory = (option: ChartCategory) => {
    setModalData({
      chartCategory: option,
    });
    showModal();
  };
  const menuState = useMenuState();

  const handleClickCategory = (chartCategory: ChartCategory) => () => {
    setSelectedCategory(chartCategory);
    analytics.track({
      event: EVENTS.CLICK_ADD_TO_TIMELINE,
      properties: {
        clickLocation: "navbar",
        chartCategory,
      },
    });
  };

  const handleClickBtn = () =>
    analytics.track({ event: EVENTS.CLICK_ADD_DATA_BTN });

  return (
    <>
      <MenuButton {...menuState} as={OutlineButton} onClick={handleClickBtn}>
        <Icon name="add" />
        Add
      </MenuButton>
      <DropdownMenu aria-label="More" {...menuState}>
        <DropdownHeader>Add to timeline:</DropdownHeader>
        {chartCategoryConfig.map((category) => (
          <DropdownMenuItem
            {...menuState}
            onClick={handleClickCategory(category.category)}
            key={category.category}
          >
            <CategorySelectedOption option={category} />
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    </>
  );
};
