import React, { useMemo } from "react";
import { AreaClosed } from "@visx/shape";
import { curveLinear } from "@visx/curve";
import { max } from "d3-array";
import { scaleLinear } from "@visx/scale";
import {
  TimeScale,
} from "../helpers";
import { Labels, Alignment } from "../Labels";
import { useCallback } from "react";
import { ChartDataDisplay, ColorConfig, getTransparentColor } from "../config";

const getValue = (d: ChartDataDisplay) => d.value || 5;

export type ChartAreaProps = {
  width: number;
  areaData: ChartDataDisplay[];
  colors: ColorConfig;
  timeScale: TimeScale;
};
const chartHeight = 250;
const chartPaddingTop = 2;

export const ChartArea = ({
  width,
  areaData,
  colors,
  timeScale,
}: ChartAreaProps) => {
  const sortedAreaData = useMemo(
    () =>
      [...areaData].sort(
        (a, b) => b.timestampStart.getTime() - a.timestampStart.getTime()
      ),
    [areaData]
  );

  // bounds
  const yMax = useMemo(() => chartHeight, []);

  const areaValueScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        domain: [0, (max(sortedAreaData, getValue) || 0) + chartPaddingTop],
        nice: true,
      }),
    [yMax, sortedAreaData]
  );

  const getX = useCallback(
    (d: ChartDataDisplay) => timeScale(d.timestampStart) || 0,
    [timeScale]
  );
  const getY = useCallback(
    (d?: ChartDataDisplay) => (d ? areaValueScale(getValue(d)) : 0),
    [areaValueScale]
  );

  if (!sortedAreaData.length) {
    return null;
  }
  return (
    <>
      <svg width={width} height={chartHeight}>
        <g>
          <AreaClosed<ChartDataDisplay>
            data={sortedAreaData}
            x={getX}
            y={getY}
            yScale={areaValueScale}
            strokeWidth={1}
            fill={getTransparentColor(colors.fill)}
            curve={curveLinear}
          />
        </g>
        <Labels
          data={sortedAreaData}
          getX={getX}
          alignment={Alignment.top}
        />
      </svg>
    </>
  );
};
