import styled from "styled-components";
import React from "react";
import {
  Popover as ReakitPopover,
  PopoverDisclosure,
  PopoverDisclosureHTMLProps,
  PopoverStateReturn,
} from "reakit/Popover";
import { Portal } from "reakit/Portal";

import { colors } from "../../constants/theme";

const StyledPopover = styled(ReakitPopover)({
  fontSize: 16,
  fontWeight: 500,
  color: colors.dark85,
  lineHeight: `24px`,
  borderRadius: 3,
  boxShadow: "0px 2px 10px rgb(15 40 63 / 20%)",
  background: colors.white,
  zIndex: 100,
});

export type PopoverDisclosureProps = React.FunctionComponentElement<PopoverDisclosureHTMLProps>;

export type PopoverProps = React.ComponentPropsWithoutRef<typeof ReakitPopover> & {
  popover: PopoverStateReturn;
  disclosure: PopoverDisclosureProps;
};

export const Popover = ({ disclosure, popover, ...props }: PopoverProps) => {
  return (
    <>
      <PopoverDisclosure
        {...popover}
        ref={disclosure.ref}
        {...disclosure.props}
      >
        {(disclosureProps) => React.cloneElement(disclosure, disclosureProps)}
      </PopoverDisclosure>
      <Portal>
        {popover.visible && (
          <StyledPopover {...popover}>{props.children}</StyledPopover>
        )}
      </Portal>
    </>
  );
};
