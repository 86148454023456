import { darken, transparentize } from "polished";
import { Button as ReakitButton} from "reakit";
import styled from "styled-components";
import { colors } from "../../constants/theme";
import { missingCaseError } from "../../helpers/missingCaseError";

export enum ButtonSize {
  'small',
  'normal',
  'large',
}

const getPadding = (buttonSize: ButtonSize) => {
  switch(buttonSize) {
    case ButtonSize.small:
      return {
        paddingLeft: 5,
        paddingRight: 5,
      };
    case ButtonSize.large:
      return {
        paddingLeft: 30,
        paddingRight: 30,
      };

    case ButtonSize.normal:
      return {
        paddingLeft: 20,
        paddingRight: 20,
      };

    default:
      return missingCaseError(buttonSize);
  }
}

export const Button = styled(ReakitButton)<{ color?: string; size?: ButtonSize }>(
  ({ color, size = ButtonSize.normal }) => ({
    border: "none",
    borderRadius: 4,
    height: "40px",
    fontFamily: "Graphik Medium",
    fontWeight: 500,
    fontSize: 16,
    outline: "none",
    cursor: "pointer",
    textAlign: "center",
    ...getPadding(size),
    color: colors.white,
    backgroundColor: color || colors.light90,
    overflow: "hidden",
    "&:disabled": {
      backgroundColor: transparentize(0.3, color || colors.light90),
    },
    "&:hover, &:focus, &:active": {
      backgroundColor: darken(0.02, color || colors.light90),
    },
  })
);
