import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from "@reach/router";

import { Header, LogoIconLeftLink, StyledWrapper } from "./Login";
import { Button, ButtonSize } from './base/Button';
import { routes } from '../App';
import { colors } from '../constants/theme';
import { LogoFull } from './base/LogoFull';

export const AuthWrapper = styled("div")({
  width: "100vw",
  minHeight: "100vh",
  backgroundColor: colors.light90,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 30,
  boxSizing: "border-box",
});

export const StaticWrapper = ({
  children,
  showBottomLogo = true,
}: {
  children?: React.ReactNode;
  showBottomLogo?: boolean;
}) => (
  <StyledWrapper>
    <LogoFull />
    {children}
    {showBottomLogo && <LogoIconLeftLink />}
  </StyledWrapper>
);

export const Welcome = (props: RouteComponentProps) => {
  const handleGo: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    props.navigate?.(routes.timeline);
  };

  return (
    <AuthWrapper>
      <StaticWrapper>
        <Header>Let's get started!</Header>
        <Button
          onClick={handleGo}
          size={ButtonSize.large}
          type="submit"
          color={colors.burgundy}
        >
          Let's go
        </Button>
      </StaticWrapper>
    </AuthWrapper>
  );
};
