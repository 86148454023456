import { Radio, RadioGroup, RadioStateReturn } from "reakit/Radio";
import styled from "styled-components";

import { DidItHelp } from "../../../data/graphql/generated/graphql";
import { colors } from "../../../constants/theme";
import { missingCaseError } from "../../../helpers/missingCaseError";
import { Icon } from "../../base/Icon";

const StyledRadioGroup = styled(RadioGroup)<{ readOnly: boolean }>(({ readOnly }) => ({
  display: 'flex',
  alignItems: 'center',
  ...(readOnly && ({
    pointerEvents: 'none',
  })),
}));

const ButtonLabel = styled.label({
  borderRadius: 3,
  height: 40,
  display: "flex",
  gap: 6,
  alignItems: "center",
  padding: '0 13px',
  cursor: 'pointer',
  userSelect: 'none',
  marginRight: 13,
});

const HiddenRadio = styled(Radio)<{ color: string }>(({ color }) => ({
  appearance: "none",
  margin: 0,
  [`& + ${ButtonLabel}`]: {
    backgroundColor: "transparent",
    border: `1px solid ${color}`,
    color,
  },
  [`&:checked + ${ButtonLabel}`]: {
    color: colors.dark,
    backgroundColor: color,
  },
}));

export const getThreeButtonsColors = (didItHelp: DidItHelp) => {
  switch (didItHelp) {
    case DidItHelp.Yes:
      return colors.green;
    case DidItHelp.NotSure:
      return colors.light110;
    case DidItHelp.No:
    case DidItHelp.SideEffects: // temp
      return colors.red;
    default:
      return missingCaseError(didItHelp);
  }
}

export const ThreeButtons = ({ readOnly, ...radioState }: RadioStateReturn & { readOnly: boolean }) => {
  const shouldDisplay = (value: DidItHelp) => !readOnly || radioState.state === value;

  return (
    <StyledRadioGroup
      {...radioState}
      disabled={readOnly}
      readOnly={readOnly}
      aria-label="helpfulness"
    >
      {shouldDisplay(DidItHelp.Yes) && (
        <>
          <HiddenRadio
            {...radioState}
            value={DidItHelp.Yes}
            id={DidItHelp.Yes}
            color={getThreeButtonsColors(DidItHelp.Yes)}
          />
          <ButtonLabel htmlFor={DidItHelp.Yes}>
            <Icon name="check" />
            Yes
          </ButtonLabel>
        </>
      )}
      {shouldDisplay(DidItHelp.NotSure) && (
        <>
          <HiddenRadio
            {...radioState}
            value={DidItHelp.NotSure}
            id={DidItHelp.NotSure}
            color={getThreeButtonsColors(DidItHelp.NotSure)}
          />
          <ButtonLabel htmlFor={DidItHelp.NotSure}>
            <Icon name="question_mark" />
            Not sure
          </ButtonLabel>
        </>
      )}
      {shouldDisplay(DidItHelp.No) && (
        <>
          <HiddenRadio
            {...radioState}
            value={DidItHelp.No}
            id={DidItHelp.No}
            color={getThreeButtonsColors(DidItHelp.No)}
          />
          <ButtonLabel htmlFor={DidItHelp.No}>
            <Icon name="cancel" />
            No
          </ButtonLabel>
        </>
      )}
    </StyledRadioGroup>
  );
};
