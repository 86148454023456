import styled from "styled-components";
import { Modal, ModalBody, ModalHeader, ModalHeading, ModalSubheading } from "../../base/Modal";
import { DialogStateReturn } from "reakit";
import { MyChartDataAccessContext } from "../../../context/MyChartDataAccessContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { ChartDataAccessType, useChartDataAccessToUserLazyQuery } from "../../../data/graphql/generated/graphql";
import { useApolloClient } from "@apollo/client";
import { colors } from "../../../constants/theme";
import { Loader } from "../../base/Loader";
import { ChartDataAccessPerson, PersonWithAccess } from "./PersonWithAccess";
import { missingCaseError } from "../../../helpers/missingCaseError";
import { RemovalModal } from "./RemovalModal";

const ModalHeaderColumn = styled('div')({
  display: "flex",
  flexDirection: "column",
});

const PeopleWithAccessHeader = styled("h6")({
  fontFamily: 'Graphik Medium',
  fontWeight: 500,
  fontSize: 18,
  lineHeight: "45px",
  color: colors.dark,
  borderBottom: `1px solid ${colors.light110}`,
  'span': {
    fontFamily: 'Graphik Regular',
  }
});

const PeopleWithAccessList = styled('ul')({
  margin: 0,
  padding: '0 0 20px',
  listStyle: 'none',
});

const LoaderWrap = styled('div')({
  textAlign: 'center',
  padding: 15,
});

const accessTypeSortOrder: { [key in ChartDataAccessType]: number} = {
  [ChartDataAccessType.SuperAdmin]: 0,
  [ChartDataAccessType.Owner]: 1,
  [ChartDataAccessType.Write]: 2,
  [ChartDataAccessType.Read]: 3,
};

enum ModalMode {
  list,
  remove,
}

export const SharingModal = ({ modalState}: { modalState: DialogStateReturn }) => {
  const client = useApolloClient();

  const myChartDataAccessContext = useContext(MyChartDataAccessContext);
  const currentlyViewingDisplayName =
    myChartDataAccessContext.currentlyViewingUser?.displayName;
  const currentlyViewingId =
      myChartDataAccessContext.currentlyViewingUser?.id;
  const [
    chartDataAccessToUserLazyQuery,
    { data: chartDataAccessToUserData, loading },
  ] = useChartDataAccessToUserLazyQuery({ client });

  const sortedChartDataAccessToUser = useMemo(() =>
    [...(chartDataAccessToUserData?.chartDataAccessToUser || [])].sort(
      (a, b) =>
        accessTypeSortOrder[a.accessType] - accessTypeSortOrder[b.accessType] || a.accessor.displayName.localeCompare(b.accessor.displayName)
    ),
    [chartDataAccessToUserData?.chartDataAccessToUser]
  );

  const [modalMode, setModalMode] = useState(ModalMode.list);
  const [personInFocus, setPersonInFocus] = useState<ChartDataAccessPerson | null>(null);

  useEffect(() => {
    if (currentlyViewingId) {
      chartDataAccessToUserLazyQuery({
        variables: {
          aboutUserId: parseInt(currentlyViewingId),
        }
      });
    }
  }, [chartDataAccessToUserLazyQuery, currentlyViewingId]);

  if (!currentlyViewingId) {
    return null;
  }

  const handleRemoveAccess = (person: ChartDataAccessPerson) => {
    setPersonInFocus(person);
    setModalMode(ModalMode.remove);
  };


  const getContents = () => {
    switch (modalMode) {
      case ModalMode.list:
        return (
          <>
            <ModalHeader {...modalState}>
              <ModalHeaderColumn>
                <ModalHeading>Manage access</ModalHeading>
                <ModalSubheading>
                  to {currentlyViewingDisplayName}
                </ModalSubheading>
              </ModalHeaderColumn>
            </ModalHeader>
            <ModalBody>
              {loading ? (
                <LoaderWrap>
                  <Loader />
                </LoaderWrap>
              ) : (
                <>
                  <PeopleWithAccessHeader>
                    People with access{" "}
                    <span>
                      ({chartDataAccessToUserData?.chartDataAccessToUser.length}
                      )
                    </span>
                  </PeopleWithAccessHeader>
                  <PeopleWithAccessList>
                    {sortedChartDataAccessToUser?.map((person) => (
                      <PersonWithAccess
                        person={person}
                        key={person.accessor.id}
                        onRemoveAccess={handleRemoveAccess}
                        currentlyViewingId={currentlyViewingId}
                      />
                    ))}
                  </PeopleWithAccessList>
                </>
              )}
            </ModalBody>
          </>
        );
      case ModalMode.remove:
          return personInFocus ? (
            <RemovalModal
              person={personInFocus}
              {...modalState}
              currentlyViewingId={currentlyViewingId}
              onBack={() => setModalMode(ModalMode.list)}
            />
          ) : null;
      default:
        return missingCaseError(modalMode);
    }
  }

  return (
    <Modal {...modalState} maxWidth={475} isDismissible={true}>
      {getContents()}
    </Modal>
  );
}