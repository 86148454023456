import LoaderImg from "../../assets/images/loader.png";
import { motion } from "framer-motion";

export const Loader = () => (
  <motion.img
    initial={{ rotate: 0 }}
    animate={{ rotate: 360 }}
    transition={{ duration: 1, repeat: Infinity, delay: 0 }}
    src={LoaderImg}
    height={72}
    width={72}
    alt="Loading..."
  />
);
