import React, { useContext, useMemo, useState } from "react";
import { useModalState } from "../base/Modal";
import { DataModal, OptionalChartDataData } from "./DataModal";

export const DataModalContext = React.createContext<{
  setModalData: (data: OptionalChartDataData) => void;
  showModal: () => void;
}>({
  setModalData: () => {},
  showModal: () => {},
});

export const useDataModal = () => {
  return useContext(DataModalContext);
};

export const DataModalContextProvider = ({ children }: {
  children: React.ReactNode;
}) => {
  const modalState = useModalState();
  const [modalData, setModalData] = useState<
    OptionalChartDataData | undefined
  >(undefined);

  const context = useMemo(() => {
    return {
      setModalData,
      showModal: modalState.show,
    };
  }, [setModalData, modalState.show]);

  return (
    <DataModalContext.Provider value={context}>
      {children}
      {modalState.visible && <DataModal chartData={modalData} modalState={modalState} />}
    </DataModalContext.Provider>
  );
};