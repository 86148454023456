import { useMemo } from "react";
import { PopoverInitialState, usePopoverState, Popover, PopoverDisclosure, PopoverStateReturn } from "reakit/Popover";
import styled, { CSSObject } from "styled-components";

import { colors } from "../../constants/theme";
import { Button } from "./Button";

export type SelectItem = {
  node: React.ReactNode;
  value: string;
};

interface SelectState extends PopoverStateReturn {
  selected: string | null;
  setSelected: (newOption: string) => void;
  initialPopoverState?: PopoverInitialState;
};

type SelectProps = React.ComponentPropsWithoutRef<typeof Popover> & SelectState;

const borderRadius = 3;

const StyledPopover = styled(Popover)({
  boxShadow: "0px 2px 10px rgb(15 40 63 / 20%)",
  border: `1px solid ${colors.light110}`,
  borderRadius,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  zIndex: 100,
  backgroundColor: colors.white,
});

export const sharedOptionStyles: CSSObject = {
  display: "flex",
  alignItems: "center",
  gap: 10,
  borderRadius: 0,
  justifyContent: "flex-start",
  padding: `11px 20px 11px 20px`,
  color: colors.buttonBorder,
  fontSize: 16,
  height: 40,
  boxSizing: "border-box" as const,
  ":hover": {
    background: colors.light90,
  },
};

export const StyledSelectOption = styled(Button)(({ selected }: { selected?: boolean }) => ({
  ...sharedOptionStyles,
  background: selected ? colors.light90 : colors.white,
  flexGrow: 1,
  fontWeight: 400,
  fontFamily: "Graphik Regular",
}));

type SelectTriggerProps = React.ComponentPropsWithoutRef<typeof PopoverDisclosure> & SelectState;

const Trigger: React.FC<SelectTriggerProps> = ({
  selected,
  setSelected,
  children,
  ...props
}) => <PopoverDisclosure {...props}>{children}</PopoverDisclosure>;

export const SelectTrigger = styled(Trigger)({
  border: "none",
  outline: "none",
  cursor: "pointer",
  background: "none",
  display: "flex",
  alignItems: "center",
  gap: 8,
  padding: "0 10px",
}) as React.FC<SelectTriggerProps>;

export const useSelectState = ({
  selected,
  setSelected,
  initialPopoverState,
}: {
  selected: string | null;
  setSelected: (newOption: string) => void;
  initialPopoverState?: PopoverInitialState;
}) => {
  const popoverState = usePopoverState(initialPopoverState);

  return useMemo(
    () => ({
      ...popoverState,
      selected,
      setSelected,
    }),
    [popoverState, selected, setSelected]
  );
};

export const Select = ({ selected, setSelected, children, ...props }: SelectProps) => {
  return (
    <StyledPopover {...props}>
      {children}
    </StyledPopover>
  );
};

type SelectOptionProps = SelectState & {
  value: string;
} & React.ComponentPropsWithoutRef<'div'>;

export const SelectOption = ({ value, selected, setSelected, hide, children }: SelectOptionProps) => {
  return (
      <StyledSelectOption
        onClick={() => {
          setSelected(value);
          hide();
        }}
        key={value}
        selected={value === selected}
      >
        {children}
      </StyledSelectOption>
  );
};
