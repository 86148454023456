export enum EVENTS {
  CLICK_ADD_DATA_BTN = "Click: add data button",
  // Aliased to "Click: add data by category" in Mixpanel
  CLICK_ADD_TO_TIMELINE = "Click: add data to timeline",
  CLICK_AUTH_LOGIN_BTN = "Click: log in button",
  CLICK_DATA_MODAL_ADD_BTN = "Click: data modal add button",
  CLICK_DATA_MODAL_SAVE_BTN = "Click: data modal save button",
  CLICK_DOWNLOAD_START_BTN = "Click: download start button",
  CLICK_DOWNLOAD_DONE_BTN = "Click: download done button",
  CLICK_FILTER_PANE_CLOSE = "Click: filter pane close",
  CLICK_FILTER_PANE_CLOSE_ALL_CATEGORIES = "Click: filter pane close all categories",
  CLICK_FILTER_PANE_EVENT = "Click: filter pane event",
  CLICK_FILTER_PANE_OPEN = "Click: filter pane open",
  CLICK_FILTER_PANE_OPEN_ALL_CATEGORIES = "Click: filter pane open all categories",
  CLICK_FILTER_PANE_HIDE_CATEGORY = "Click: filter pane hide category",
  CLICK_FILTER_PANE_SHOW_CATEGORY = "Click: filter pane show category",
  CLICK_FILTER_PANE_HIDE_EVENT = "Click: filter pane hide event",
  CLICK_FILTER_PANE_SHOW_EVENT = "Click: filter pane show event",
  CLICK_SHARE_BTN = "Click: share button",
  CLICK_MANAGE_ACCESS_BTN = "Click: manage access button",
  CLICK_TIMELINE_EVENT = "Click: timeline event",
  CLICK_TIMELINE_EVENT_LABEL = "Click: timeline event label",
  CLICK_TIMELINE_EVENT_MULTIPLE_MENU = "Click: timeline event from multiple menu",
  TIMELINE_INTERACTION_ZOOM = "Timeline interaction: zoom",
  TIMELINE_INTERACTION_PAN = "Timeline interaction: pan",
};
