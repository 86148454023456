import { ChartDataAccessType } from "../../data/graphql/generated/graphql";
import { missingCaseError } from "../../helpers/missingCaseError";
import { Icon } from "./Icon";

export const AccessIcon = ({ accessType }: { accessType: ChartDataAccessType }) => {
  switch (accessType) {
    case ChartDataAccessType.SuperAdmin:
      return <Icon name='shield_person' />;
    case ChartDataAccessType.Owner:
      return <Icon name='lock' />;
    case ChartDataAccessType.Write:
      return <Icon name='stylus' />;
    case ChartDataAccessType.Read:
      return <Icon name='visibility_lock' />;
    default:
      throw missingCaseError(accessType);
  }
}