import React, { MouseEventHandler, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Text } from "@visx/text";
import { motion } from "framer-motion";
import { colors } from "../../constants/theme";
import { DisplayDates, getDateDisplayEnd, getDateDisplayStart, getLabel } from './helpers';
import { useCallback } from "react";
import { useMeasure } from "react-use";
import { useDataModal } from "./DataModalContext";
import { MultipleMenu } from "./MultipleMenu";
import { useTooltipState } from "../base/Tooltip";
import { MoreInfoTooltip } from "./MoreInfoTooltip";
import { ChartDataDisplay, Color } from "./config";
import { DownloadModeContext } from "../../context/DownloadModeContext";
import styled from "styled-components";
import { SubCategory } from "../../data/graphql/generated/graphql";
import { analytics } from "../../analytics";
import { EVENTS } from "../../analytics/events";

export const labelStyles: React.CSSProperties = {
  // Need to update eventually to be consistent
  // fontFamily: "Graphik Medium",
  fontStyle: "normal",
  // fontWeight: 500,
  fontSize: "15px",
  display: "flex",
  alignItems: "center",
  userSelect: "none",
};

const dateStyles: React.CSSProperties = {
  ...labelStyles,
  // fontFamily: "Graphik Regular",
};

const quasiLineHeight = 16;

export enum Alignment {
  top,
  bottom,
}

type GetX = (d: ChartDataDisplay) => number;

const defaultTextWidth = 70;

export const getDatesDisplay = (dataDisplay: DisplayDates) => {
  const startDisplayDate = getDateDisplayStart(dataDisplay);
  const endDisplayDate = getDateDisplayEnd(dataDisplay);

  const dateStrings = [startDisplayDate];

  if (endDisplayDate) {
    dateStrings.push(endDisplayDate);
  }

  // Don't show the same day twice
  const stringSet = new Set(dateStrings);
  return Array.from(stringSet.values()).join("-");
};

type GetAlignment = {
  alignment: Alignment;
  labelHeight: number;
  dateHeight: number;
};
const getAlignment = ({ alignment, labelHeight, dateHeight }: GetAlignment) => {
  let labelDy = 0;
  let dateDy = 0;

  switch (alignment) {
    case Alignment.bottom:
      labelDy = 0;
      dateDy = labelHeight;
      break;
    case Alignment.top:
    default:
      labelDy = -dateHeight;
      dateDy = labelHeight - dateHeight;
      break;
  }

  return {
    labelDy,
    dateDy,
  };
};

type LabelProps = {
  id: string;
  left: number;
  top: number;
  labelText: string;
  dateText: string;
  alignment: Alignment;
  textWidth?: number;
  reportWidth?: (width: number) => void;
  reportHeight?: (height: number) => void;
  onClick: MouseEventHandler<SVGGElement>;
  notes?: string | null;
  textAnchor?: "start" | "end";
  subCategory?: Pick<SubCategory, "name" | "color"> | null;
};

const Label = React.memo(
  ({
    id,
    left,
    top,
    labelText,
    dateText,
    alignment,
    textWidth = defaultTextWidth,
    reportWidth,
    reportHeight,
    onClick,
    notes,
    textAnchor,
    subCategory,
  }: LabelProps) => {
    const [
      labelRef,
      { width: measuredLabelWidth, height: measuredLabelHeight },
    ] = useMeasure<SVGSVGElement>();
    const [dateRef, { width: dateWidth, height: measuredDateHeight }] =
      useMeasure<SVGSVGElement>();

    const dateHeight = measuredLabelHeight + measuredDateHeight;
    const measuredWidth = Math.max(measuredLabelWidth, dateWidth);

    useEffect(() => {
      if (reportWidth) reportWidth(measuredWidth);
    }, [reportWidth, measuredWidth]);

    useEffect(() => {
      if (reportHeight) reportHeight(dateHeight);
    }, [reportHeight, dateHeight]);

    const { labelDy, dateDy } = getAlignment({
      alignment,
      labelHeight: measuredLabelHeight,
      dateHeight,
    });

    const tooltipState = useTooltipState({
      placement: "top-start",
    });

    return (
      <MoreInfoTooltip
        key={`${id}-tooltip`}
        label={labelText}
        dateText={dateText}
        notes={notes}
        tooltipState={tooltipState}
        subCategory={subCategory}
      >
        {/* This SVG looks like it's unnecessary, but it prevents MoreInfoTooltip from wiping out the overflow style that we apply manually on the inner SVG so that our PDF will render properly */}
        <svg>
          <svg
            style={{ overflow: "visible" }}
            key={`${id}-tooltip`}
            x={left}
            onClick={onClick}
          >
            <Text
              y={top}
              dy={labelDy}
              width={textWidth}
              verticalAnchor="start"
              innerRef={labelRef}
              textAnchor={textAnchor}
              style={labelStyles}
              className="chart-label-text"
              fontFamily="Graphik Medium"
              lineHeight={quasiLineHeight}
              fill={colors.dark}
            >
              {labelText}
            </Text>
            <Text
              y={top}
              dy={dateDy}
              width={textWidth}
              verticalAnchor="start"
              innerRef={dateRef}
              textAnchor={textAnchor}
              style={dateStyles}
              className="chart-label-date"
              fontFamily="Graphik Regular"
              lineHeight={quasiLineHeight}
              fill={colors.dark}
            >
              {dateText}
            </Text>
          </svg>
        </svg>
      </MoreInfoTooltip>
    );
  }
);

type PartialPosition = {
  top: number;
  left: number;
  textAnchor: "start" | "end";
};

type Position = PartialPosition & {
  width: number;
  height: number;
};

const verticalLabelPadding = 7;
const horizontalLabelOffset = 7;
const lineStroke = 1;
const notchLength = 3;

const getLeftWithOffset = ({ left, textAnchor}: { left: number; textAnchor: 'start' | 'end' }) => {
  if (textAnchor === 'start') {
    return left + horizontalLabelOffset;
  }

  return left - horizontalLabelOffset;
};

const labelTopMargin = 8;

function getFinalPlacement({
  left: initialLeft,
  width,
  prevPos,
  nextLeft,
}: {
  left: number;
  width: number | null;
  prevPos: Position | null;
  nextLeft: number | null;
}) {
  // - render first label left aligned below middle of data point
  const returns: PartialPosition = {
    left: initialLeft,
    top: labelTopMargin,
    textAnchor: "start" as const,
  };

  if (!width) {
    return returns;
  }

  // - does label intersect x bound of data to right? if no, keep, move on
  if (
    nextLeft === null ||
    getLeftWithOffset({ left: returns.left, textAnchor: returns.textAnchor }) +
      width <
      nextLeft
  ) {
    return returns;
  }

  // - if yes, does right aligning intersect label to left? if no, keep, move on
  // bug: this needs to account for position, width, and textAnchor
  if (
    !prevPos ||
    getLeftWithOffset({ left: returns.left, textAnchor: "end" }) - width >
        (prevPos.textAnchor === "start" ? getLeftWithOffset({
        left: prevPos.left,
        textAnchor: prevPos.textAnchor,
      }) + prevPos.width : prevPos.left)
  ) {
    returns.textAnchor = "end" as const;
    return returns;
  }

  // - if yes, move height down below height of data to left
  returns.top = prevPos.top + prevPos.height + verticalLabelPadding;
  returns.textAnchor = "end" as const;

  return returns;
}

const MultipleButtonAndLines = ({
  labels,
  getX,
  color,
  thresholdDisplay,
}: {
  labels: ChartDataDisplay[];
  getX: GetX;
  color: Color;
  thresholdDisplay: number;
}) => {
  const combinerLineX = useMemo(() => getX(labels[0]), [labels, getX]);

  const combinerLineWidth = useMemo(
    () => getX(labels[labels.length - 1]) - combinerLineX,
    [labels, getX, combinerLineX]
  );

  const multipleButtonLeft = useMemo(
    () => getX(labels[labels.length - 1]) - combinerLineWidth / 2,
    [labels, getX, combinerLineWidth]
  );

  const getDisplayInfo = (dataPoint: ChartDataDisplay) => ({
    label: getLabel(dataPoint),
    displayDates: getDatesDisplay(dataPoint),
  });

  return (
    <>
      <rect
        fill={color.color}
        height={lineStroke}
        width={combinerLineWidth + lineStroke}
        x={combinerLineX - lineStroke / 2}
        y={thresholdDisplay}
        rx="0"
      />
      <rect
        fill={color.color}
        height={notchLength}
        width={lineStroke}
        x={multipleButtonLeft - lineStroke / 2}
        y={thresholdDisplay + lineStroke}
        rx="0"
      />
      <MultiplesButton
        dataPoints={labels}
        left={multipleButtonLeft}
        top={thresholdDisplay + lineStroke + notchLength + 3}
        getDisplayInfo={getDisplayInfo}
      />
    </>
  );
};

const LabelMaker = ({
  remainingLabels,
  combinedLabels = [],
  combinedLabelTop,
  prevPos,
  ...restProps
}: {
  remainingLabels: ChartDataDisplay[];
  combinedLabels?: ChartDataDisplay[];
  combinedLabelTop?: number | null;
  getX: GetX;
  alignment: Alignment;
  textWidth?: number;
  prevPos: Position | null;
  color?: Color;
  doneRenderingLabels?: (lastRender: number) => void;
  hideLine: boolean;
}) => {
  const {
    getX,
    alignment,
    textWidth,
    color,
    doneRenderingLabels,
    hideLine,
  } = restProps;
  const [width, setWidth] = useState<number | null>(null);
  const [height, setHeight] = useState<number | null>(null);
  const currentLabel = remainingLabels[0];
  const nextLabel = remainingLabels[1];
  const labelText = getLabel(currentLabel);
  const dateText = getDatesDisplay(currentLabel);
  const left = getX(currentLabel);
  const nextLeft = nextLabel ? getX(nextLabel) : null;
  const restOfLabels = remainingLabels.slice(1);

  const partialPosition = useMemo(
    () =>
      getFinalPlacement({
        left,
        prevPos,
        width,
        nextLeft,
      }),
    [left, prevPos, width, nextLeft]
  );
  // render and measure label
  // position it based on what we know so far
  // keep passing info for other labels

  const newPrevPos: Position | null = useMemo(() => {
    if (width !== null && height !== null) {
      return {
        ...partialPosition,
        height,
        width,
      };
    }

    return null;
  }, [partialPosition, height, width]);

  useEffect(() => {
    if (!restOfLabels.length && doneRenderingLabels) {
      doneRenderingLabels(Date.now());
    }
  });

  const { setModalData, showModal } = useDataModal();
  const {isDownloadMode} = useContext(DownloadModeContext);
  const threshold = isDownloadMode ? null : 225;

  const shouldCombineLabel = useMemo(
    () => threshold ? partialPosition.top > threshold : false,
    [partialPosition.top, threshold]
  );
  const { newCombinedLabels, newCombinedLabelTop } = useMemo(() => {
    if (shouldCombineLabel) {
      return {
        newCombinedLabels: [...combinedLabels, currentLabel],
        newCombinedLabelTop:
          combinedLabelTop || (partialPosition.top + (height || 0) / 2),
      };
    }
    return {
      newCombinedLabels: [],
      newCombinedLabelTop: null,
    };
  }, [
    combinedLabels,
    currentLabel,
    shouldCombineLabel,
    combinedLabelTop,
    partialPosition.top,
    height,
  ]);
  const thresholdDisplay = useMemo(
    () =>
      Math.max(combinedLabelTop || 0, newCombinedLabelTop || 0) ||
      threshold ||
      0,
    [combinedLabelTop, newCombinedLabelTop, threshold]
  );
  const lineBottom = shouldCombineLabel
    ? thresholdDisplay
    : partialPosition.top + (quasiLineHeight / 2) - 2;
  const lineLength = lineBottom;

  // If there's no line, no need for an offset
  const labelLeft = hideLine
    ? partialPosition.left
    : getLeftWithOffset({
        left: partialPosition.left,
        textAnchor: partialPosition.textAnchor,
      });

  return (
    <>
      {!!combinedLabels.length && !shouldCombineLabel && color && (
        <MultipleButtonAndLines
          labels={combinedLabels}
          getX={getX}
          color={color}
          thresholdDisplay={thresholdDisplay}
        />
      )}
      {color && lineLength && !hideLine && (
        <>
          <svg
            className="chart-lines"
            x={partialPosition.left - lineStroke / 2}
            y="0"
          >
            <rect
              width={lineStroke}
              height={lineLength}
              x={0}
              y={0}
              rx="0"
              fill={color.color}
            />
          </svg>
          {!shouldCombineLabel && (
            <svg
              className="chart-lines"
              x={
                partialPosition.left -
                lineStroke / 2 -
                (partialPosition.textAnchor === "start"
                  ? -lineStroke
                  : notchLength)
              }
              y={lineLength - lineStroke}
            >
              <rect
                width={notchLength}
                height={lineStroke}
                x={0}
                y={0}
                rx="0"
                fill={color.color}
              />
            </svg>
          )}
        </>
      )}
      {!shouldCombineLabel && (
        <Label
          key={currentLabel.id}
          id={currentLabel.id}
          left={labelLeft}
          top={partialPosition.top}
          labelText={labelText}
          dateText={dateText}
          alignment={alignment}
          textWidth={textWidth}
          reportWidth={setWidth}
          reportHeight={setHeight}
          onClick={() => {
            setModalData(currentLabel);
            showModal();
            analytics.track({
              event: EVENTS.CLICK_TIMELINE_EVENT_LABEL,
              properties:{
                chartCategory: currentLabel.chartCategory,
              }
            });
          }}
          notes={currentLabel.notes}
          textAnchor={partialPosition.textAnchor}
          subCategory={currentLabel.subCategory}
        />
      )}
      {restOfLabels.length ? (
        <LabelMaker
          {...restProps}
          remainingLabels={restOfLabels}
          combinedLabels={newCombinedLabels}
          prevPos={newPrevPos}
          combinedLabelTop={newCombinedLabelTop}
        />
      ) : null}
    </>
  );
};

type LabelsProps = {
  data: ChartDataDisplay[];
  getX: GetX;
  alignment: Alignment;
  textWidth?: number;
  color?: Color;
  chartWidth?: number;
  hideLine?: boolean;
};

// This relies on other charts having a strokewidth of 1...
const LabelsWrapper = styled(motion.svg)({
  transform: 'translateY(-.5px)',
});

export const Labels = ({
  data,
  getX,
  alignment,
  textWidth,
  color,
  chartWidth = 500,
  hideLine = false,
}: LabelsProps) => {
  const [labelsHeight, setLabelsHeight] = useState(0);
  const svgRef = useRef<SVGSVGElement>(null);

  const doneRenderingLabels = useCallback(() => {
    if (svgRef.current) {
      const { height } = svgRef.current.getBBox();
      // Add a little magic cushion here to prevent
      // labels being cut off for unknown reasons
      setLabelsHeight(height + 5);
    }
  }, []);

  return (
    <LabelsWrapper
      width={chartWidth}
      height={labelsHeight}
      // Need a way to not run this when downloading...
      // animate={{ height: labelsHeight }}
      // transition={{ duration: 0.2 }}
      ref={svgRef}
      className="chart-labels"
    >
      <LabelMaker
        getX={getX}
        alignment={alignment}
        textWidth={textWidth}
        remainingLabels={data}
        prevPos={null}
        color={color}
        doneRenderingLabels={doneRenderingLabels}
        hideLine={hideLine}
      />
    </LabelsWrapper>
  );
};

const MultiplesButton = React.memo(
  ({
    dataPoints,
    top,
    left,
    getDisplayInfo,
  }: {
    dataPoints: ChartDataDisplay[];
    top: number;
    left: number;
    getDisplayInfo: (dataPoint: ChartDataDisplay) => {
      label: string;
      displayDates: string;
    };
  }) => {
    const count = dataPoints.length;
    const firstDataPoint = dataPoints[0];
    const lastDataPoint = dataPoints[count - 1];

    // This should probably be dynamic at some point;
    const rectWidth = 30;
    const rectHeight = 30;
    const adjustedLeft = left - (rectWidth / 2);

    return (
      <MultipleMenu dataPoints={dataPoints} getDisplayInfo={getDisplayInfo}>
        <motion.g
          key={`${firstDataPoint.id}-${lastDataPoint.id}`}
          width={rectWidth}
          height={rectHeight}
          // animate={{ x: adjustedLeft }}
          // transition={{ duration: 0.3 }}
          style={{ cursor: "pointer", outline: "none", x: adjustedLeft }}
          initial={false}
        >
          <rect
            width={rectWidth}
            height={rectHeight}
            x="0"
            y={top}
            rx="3"
            fill={colors.light90}
            stroke={colors.light}
          />
          <Text
            x={rectWidth / 2}
            y={top + rectHeight / 2}
            textAnchor="middle"
            verticalAnchor="middle"
            style={{
              userSelect: "none",
              ...labelStyles,
              fontFamily: "Graphik Regular",
              fontWeight: 400,
            }}
          >
            {count}
          </Text>
        </motion.g>
      </MultipleMenu>
    );
  }
);
