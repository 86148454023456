import { RouteComponentProps } from "@reach/router";
import { DownloadModeContext, useDownloadMode } from "../context/DownloadModeContext";
import { Timeline } from "./timeline/Timeline/Timeline";
import { useModalState } from "./base/Modal";
import { useMemo } from "react";
import { DownloadModal } from "./DownloadModal";
import styled from "styled-components";

const DownloadTimelineHide = styled('div')({
  visibility: 'hidden',
});

export const TimelinePage = (props: RouteComponentProps) => {
  const modalState = useModalState();
  const downloadMode = useDownloadMode();

  const modifiedModalState = useMemo(
    () => ({
      ...modalState,
      hide: () => downloadMode.toggleDownloadMode(false),
      visible: downloadMode.isDownloadMode,
    }),
    [modalState, downloadMode]
  );

  const normalTimeline = useMemo(
    () => ({
      isDownloadMode: false,
      toggleDownloadMode: downloadMode.toggleDownloadMode,
    }),
    [downloadMode.toggleDownloadMode]
  );

  return (
    <>
      {modifiedModalState.visible && (
        <DownloadModal modalState={modifiedModalState} />
      )}
      {/* Always keep a version as normal timeline */}
      <DownloadModeContext.Provider value={normalTimeline}>
        <Timeline />
      </DownloadModeContext.Provider>
      {downloadMode.isDownloadMode && (
        <DownloadTimelineHide>
          <Timeline />
        </DownloadTimelineHide>
      )}
    </>
  );
};
