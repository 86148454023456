import React from 'react';
import { motion } from "framer-motion";

import { TimeScale } from "../helpers";
import { Labels, Alignment } from "../Labels";
import { ChartCategory, DidItHelp } from "../../../data/graphql/generated/graphql";
import { colors } from "../../../constants/theme";
import { ChartDataDisplay, ColorConfig } from "../config";
import { useEndX, useStartX } from "./helpers";
import { Bubble } from "./ChartBubble";

type RangeBarColors = {
  [key in DidItHelp]: ColorConfig;
};

const rangeBarColors: RangeBarColors = {
  [DidItHelp.Yes]: {
    fill: {
      color: colors.green,
      opacity: 0.7,
    },
    stroke: {
      color: colors.green,
      opacity: 0.3,
    },
  },
  [DidItHelp.No]: {
    fill: {
      color: colors.red,
      opacity: 0.7,
    },
    stroke: {
      color: colors.red,
      opacity: 0.3,
    },
  },
  [DidItHelp.NotSure]: {
    fill: {
      color: colors.light110,
      opacity: 0.8,
    },
    stroke: {
      color: colors.light110,
      opacity: 0.4,
    },
  },
  // Currently unused
  [DidItHelp.SideEffects]: {
    fill: {
      color: colors.light110,
      opacity: 0.6,
    },
    stroke: {
      color: colors.light110,
      opacity: 0.2,
    },
  },
};

const barMargin = 0;
const textWidth = 150;

const strokeWidth = 1;

export type ChartRangeBarProps = {
  width: number;
  rangeBarData: ChartDataDisplay[];
  timeScale: TimeScale;
  colors: ColorConfig;
  shouldUseColors?: boolean;
  category: ChartCategory;
};

export const ChartRangeBar = ({
  width,
  rangeBarData,
  timeScale,
  colors,
  shouldUseColors,
  category,
}: ChartRangeBarProps) => {
  const getStartX = useStartX({ timeScale });
  const getEndX = useEndX({ timeScale, canUseSpan: true });

  const barRadius = 8;
  const computedOffset = 20;
  // SVGs position the stroke centered on the radius
  // Half of the strokeWidth on the top and bottom needs
  // to be accounted for aka 1 full strokeWidth
  const height = barRadius*2 + barMargin + computedOffset + strokeWidth;
  const getY = (i?: number) =>
    (i || 0) * (barMargin + barRadius*2) + computedOffset;

  if (!rangeBarData.length) {
    return null;
  }

  return (
    <>
      {rangeBarData.map((d) => {
        const x = getStartX(d);
        const y = getY(0);

        const helped = d.helped || DidItHelp.NotSure;
        const barColors = shouldUseColors
          ? colors
          : rangeBarColors[helped];

        return (
          <React.Fragment key={`rangeBar=${d.id}`}>
            <motion.svg
              width={width}
              animate={{ height }}
              transition={{ duration: 0.2 }}
              key={`svg=${d.id}`}
              className="chart"
            >
              <Bubble
                bd={d}
                getX={getStartX}
                getEndX={getEndX}
                colors={barColors}
                yMax={y + barRadius * 2}
                radius={barRadius}
                isSpan={true}
              />
            </motion.svg>
            <Labels
              chartWidth={width}
              key={`label-${d.id}`}
              data={[d]}
              getX={() => x - barRadius}
              color={colors.fill}
              alignment={Alignment.bottom}
              textWidth={textWidth}
              hideLine={true}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
