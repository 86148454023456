import styled from "styled-components";
import { colors } from "../../../constants/theme";

export const Label = styled("label")({
  fontSize: 16,
  lineHeight: "17.6px",
  fontFamily: "Graphik Medium",
  color: colors.select,
  [`& span`]: {
    fontSize: 30,
  },
});
