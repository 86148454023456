export const colors = {
  dark: "#0F283F",
  white: "#FFFFFF",
  light: `#ECEEF0`,
  light90: "#F8F9F9",
  light110: "#CFD4D9",
  dark50: "#6F7E8C",
  dark75: "#516374",
  dark85: `#3F5365`,
  tangerine: `#F7B664`,
  illustrationGrey: `#E1E4E7`,
  purple: `#6B6DB1`,
  buttonBorder: `#516374`,
  buttonColor: `#6F7E8C`,
  blue: "#51A1C8",
  navy: "#174884",
  select: "#5A6B7B",
  red: "#EB736F",
  yellow: "#F5D899",
  green: "#86C88F",
  burgundy: "#A65B74",
  forest: "#286F82",
  brand: "#A65B74",
  yesButton: "#98BBC4",
};
