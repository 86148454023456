import { DialogStateReturn } from "reakit";
import styled from "styled-components";
import { ModalBody, ModalFooter, ModalHeader, ModalHeading, StyledModalFooterButtonAndLink } from "../../base/Modal";
import { ChartDataAccessPerson } from "./PersonWithAccess";
import { Button } from "../../base/Button";
import { ButtonAsLink } from "../../base/ButtonAsLink";
import { useRemoveChartDataAccessMutation } from "../../../data/graphql/generated/graphql";
import { colors } from "../../../constants/theme";
import { useAuth } from "../../../context/AuthContext";

interface RemovalModalProps extends DialogStateReturn {
  person: ChartDataAccessPerson;
  currentlyViewingId: string;
  onBack: () => void;
}

const Name = styled('span')({
  fontFamily: 'Graphik Medium',
});

export const RemovalModal = ({ person, currentlyViewingId, onBack, ...modalState }: RemovalModalProps) => {
  const [removeChartDataAccessMutation] = useRemoveChartDataAccessMutation();
  const auth = useAuth();
  const currentUser = auth?.currentUser;

  const handleRemove = async () => {
    await removeChartDataAccessMutation({
      variables: {
        chartDataAccessRemoval: {
          id: parseInt(person.id),
          aboutId: parseInt(currentlyViewingId),
          accessorId: parseInt(person.accessor.id),
        },
      },
      update(cache) {
        const normalizedId = cache.identify({
          id: person.id,
          __typename: "ChartDataAccess",
        });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
    onBack();
  }

  const isYou = currentUser && currentUser.id === person.accessor.id;

  return (
    <>
      <ModalHeader {...modalState}>
        <ModalHeading>Remove access</ModalHeading>
      </ModalHeader>
      <ModalBody>
        <p>
          We'll remove <Name>{isYou ? 'you' : person.accessor.displayName}</Name>.
        </p>
        {!isYou && <p>For privacy, we will not send them a notification.</p>}
      </ModalBody>
      <ModalFooter>
        <StyledModalFooterButtonAndLink>
          <Button color={colors.burgundy} onClick={handleRemove}>
            Remove
          </Button>
          <ButtonAsLink color={colors.burgundy} onClick={onBack}>
            Cancel
          </ButtonAsLink>
        </StyledModalFooterButtonAndLink>
      </ModalFooter>
    </>
  );
};
