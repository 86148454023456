import { MenuButton, useMenuState } from "reakit";
import { OutlineButton } from "./base/OutlineButton";
import { DropdownMenu, DropdownMenuItem } from "./base/DropdownMenu";
import { useDownloadMode } from "../context/DownloadModeContext";
import { analytics } from "../analytics";
import { EVENTS } from "../analytics/events";
import { useModalState } from "./base/Modal";
import { SharingModal } from "./timeline/SharingModal/SharingModal";
import { Icon } from "./base/Icon";

export const ShareButton = () => {
  const menuState = useMenuState();
  const { toggleDownloadMode } = useDownloadMode();
  const sharingModalState = useModalState();

  const handleShareClick = () => {
    analytics.track({
      event: EVENTS.CLICK_SHARE_BTN,
    });
  };

  const handleDownloadClick = () => {
    toggleDownloadMode(true);
    analytics.track({
      event: EVENTS.CLICK_DOWNLOAD_START_BTN,
    });
  };

  const handleManageAccessClick = () => {
    analytics.track({
      event: EVENTS.CLICK_MANAGE_ACCESS_BTN,
    });
    sharingModalState.toggle();
  };

  return (
    <>
      <MenuButton {...menuState} as={OutlineButton} onClick={handleShareClick}>
        <Icon name="ios_share" />
        Share
      </MenuButton>
      <DropdownMenu aria-label="Share" {...menuState}>
        <DropdownMenuItem
          {...menuState}
          onClick={handleDownloadClick}
          key="download"
        >
          <Icon name="picture_as_pdf" />
          Download PDF
        </DropdownMenuItem>
        <DropdownMenuItem
          {...menuState}
          onClick={handleManageAccessClick}
          key="manageAccess"
        >
          <Icon name="person_add" />
          Manage access
        </DropdownMenuItem>
      </DropdownMenu>
      {sharingModalState.visible && (
        <SharingModal modalState={sharingModalState} />
      )}
    </>
  );
};
