const delimiter = "/";

export function convertToDisplayDate(date: Date) {
  return [date.getMonth() + 1, date.getDate(), date.getFullYear()].join(delimiter);
}

export function convertDateToDisplayStartValues(date: Date) {
  return {
    displayMonth: date.getUTCMonth() + 1,
    displayDay: date.getUTCDate(),
    displayYear: date.getUTCFullYear()
  };
}

export function convertFromDisplayDate(displayDate?: string) {
  if (!displayDate) {
    return {
      month: "",
      day: "",
      year: "",
    };
  }

  const parts = displayDate.split(delimiter);

  if (parts.length === 1) {
    // Just a year
    return {
      month: "",
      day: "",
      year: parts[0],
    };
  } else if (parts.length === 2) {
    // Month and year
    return {
      month: parts[0],
      day: "",
      year: parts[1],
    };
  } else {
    // Everything
    return {
      month: parts[0],
      day: parts[1],
      year: parts[2],
    };
  }
}

export function getEstimatedValues({
  month,
  day,
  year,
}: {
  month: string;
  day: string;
  year: string;
}) {
  if (!month && !day) {
    // If there's just a year, default to the middle of the year
    return {
      month: "6",
      day: "1",
      year,
    };
  }

  if (!day) {
    // If there's just a month and a year, default to the middle of the month
    return {
      month,
      day: "15",
      year,
    };
  }

  if (!month) {
    // If there's a day and a year but no month, default to January
    return {
      month: "1",
      day,
      year,
    };
  }

  return {
    month,
    day,
    year,
  };
}

export function generateDateFromValues({
  month,
  day,
  year,
}: {
  month: string;
  day: string;
  year: string;
}) {
  const monthInt = parseInt(month, 10) - 1;
  const dayInt = parseInt(day, 10);
  const yearInt = parseInt(year, 10);

  return new Date(yearInt, monthInt, dayInt);
};

export function generateEstimatedDateFromValues({
  month,
  day,
  year,
}: {
  month: string;
  day: string;
  year: string;
}) {

  return [month, day, year].filter(a => a).join(delimiter);
};

export function generateDisplayDateFromValues({
  month,
  day,
  year,
}: {
  month?: number | null;
  day?: number | null;
  year?: number | null;
}) {
  return [month, day, year].filter(a => a).join(delimiter);
};
