import React, { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";

export const DownloadModeContext = React.createContext<{
  isDownloadMode: boolean;
  toggleDownloadMode: Dispatch<SetStateAction<boolean>>;
}>({
  isDownloadMode: false,
  toggleDownloadMode: () => {},
});

export const useDownloadMode = () => {
  return useContext(DownloadModeContext);
};

export const DownloadModeContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isDownloadMode, setIsDownloadMode] = useState(false);

  const value = useMemo(
    () => ({
      isDownloadMode,
      toggleDownloadMode: setIsDownloadMode,
    }),
    [isDownloadMode, setIsDownloadMode]
  );

  return (
    <DownloadModeContext.Provider value={value}>
      {children}
    </DownloadModeContext.Provider>
  );
};