import React, { useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { Zoom } from "@visx/zoom";
import { ScaleTime } from "d3-scale";
import { localPoint } from "@visx/event";

import { getTimeScale } from "../helpers";
import { Ticker, tickerHeight } from "../Ticker";
import { ZoomControls } from "../ZoomControls";
import { Category } from "../Category";
import { ChartBubble } from "../Charts/ChartBubble";
import { ChartArea } from "../Charts/ChartArea";
import { ChartRangeBar } from "../Charts/ChartRangeBar";
import { FilterToggleButton } from "../Filters/FilterToggleButton";
import styled from "styled-components";
import { FilterPane, FILTER_PANE_WIDTH } from "../Filters/FilterPane";
import { DownloadModeContext } from "../../../context/DownloadModeContext";
import { downloadTimelineId } from "../../DownloadModal";
import { colors } from "../../../constants/theme";
import { ChartTypes, FilteredDisplayData, useDisplayData } from "./DisplayDataContext";
import { useToday } from "../TodayContext";
import { uiConstants } from "../../../uiConstants";
import { analytics } from "../../../analytics";
import { EVENTS } from "../../../analytics/events";

const rescaleXAxis = (scale: ScaleTime<number, number>, zoom: any) => {
  let newDomain = scale.range().map((r) => {
    return scale.invert(
      (r - zoom.transformMatrix.translateX) / zoom.transformMatrix.scaleX
    );
  });
  return scale.copy().domain(newDomain);
};

const zoomFactor = 1.4;
const clickZoomFactor = 1.2;

const timescalePadding = 50;

const TimelineWrapper = styled.div<{ isFixed: boolean }>(({ isFixed }) => ({
  marginTop: isFixed ? 0 : uiConstants.navbar.height,
  position: isFixed ? "fixed" : "relative",
  top: isFixed ? uiConstants.navbar.height : 0,
  overflow: "hidden",
}));

const TimelineShifter = styled.div<{ shouldShift: boolean }>(
  ({ shouldShift }) => ({
    transform: shouldShift ? `translateX(${FILTER_PANE_WIDTH}px)` : "none",
  })
);

const Aside = styled.aside({
  position: "absolute",
  left: 0,
  top: 0,
  height: `calc(100vh - ${uiConstants.navbar.height}px)`,
  overflowX: "hidden",
  overflowY: "auto",
  width: FILTER_PANE_WIDTH,
  zIndex: 2,
});

const Main = styled.main<{ constrainHeight: boolean }>(
  ({ constrainHeight }) => ({
    height: constrainHeight
      ? `calc(100vh - ${uiConstants.navbar.height + tickerHeight}px)`
      : "auto",
    overflowX: "hidden",
    overflowY: "auto",
    flexGrow: 1,
  })
);

export const Timeline = (props: RouteComponentProps) => {
  const [shouldShowFilters, setShouldShowFilters] = useState(false);
  const [hasHandledLimitedData, setHasHandledLimitedData] = useState(false);
  const { isDownloadMode } = useContext(DownloadModeContext);

  const handleFilterToggleButtonClick = () => {
    setShouldShowFilters(!shouldShowFilters);
    analytics.track({
      event: shouldShowFilters ? EVENTS.CLICK_FILTER_PANE_CLOSE : EVENTS.CLICK_FILTER_PANE_OPEN,
    });
  };

  const id = isDownloadMode ? downloadTimelineId : "";
  const downloadWidth = 3000;

  const {
    filteredDisplayData,
    visibleDisplayData,
    minMaxDates,
    isSingleDataPoint,
  } = useDisplayData();

  const showNewUserUI = useMemo(() => isSingleDataPoint && !hasHandledLimitedData, [isSingleDataPoint, hasHandledLimitedData]);

  useEffect(() => {
    // Automatically open sidebar when there's no data, but only do it once
    // so user can close it if they want
    if (showNewUserUI) {
      setShouldShowFilters(true);
      setHasHandledLimitedData(true);
    }
  }, [showNewUserUI]);

  return (
    <TimelineWrapper isFixed={isDownloadMode} id={id}>
      {!shouldShowFilters && !isDownloadMode && (
        <FilterToggleButton onClick={handleFilterToggleButtonClick} />
      )}
      {(shouldShowFilters ||
        showNewUserUI) && (
          <Aside>
            <FilterPane
              onClose={handleFilterToggleButtonClick}
              showNewUserUI={showNewUserUI}
            />
          </Aside>
        )}
      {filteredDisplayData.length ? (
        <ParentSize
          parentSizeStyles={{
            flexGrow: 1,
          }}
        >
          {({ width, height }) => {
            const availableTimelineWidth = shouldShowFilters
              ? width - FILTER_PANE_WIDTH
              : width;

            return (
              <InnerTimeline
                height={height}
                width={
                  isDownloadMode ? downloadWidth : availableTimelineWidth
                }
                shouldShowFilters={shouldShowFilters}
                minMaxDates={minMaxDates}
                visibleDisplayData={visibleDisplayData}
              />
            );
          }}
        </ParentSize>
      ) : null}
    </TimelineWrapper>
  );
};

const getChart = ({
  displayData,
  zoomedTimeScale,
  sharedProps,
  label,
}: {
  displayData: FilteredDisplayData;
  zoomedTimeScale: ScaleTime<number, number, never>;
  sharedProps: {
    width: number;
  };
  label: string;
}) => {
  const newSharedProps = {
    ...sharedProps,
    label,
  };

  switch (displayData.config.chartType) {
    case ChartTypes.chartArea:
      return (
        <ChartArea
          areaData={displayData.data}
          {...displayData.config}
          timeScale={zoomedTimeScale}
          {...newSharedProps}
        />
      );
    case ChartTypes.chartBubble:
      return (
        <ChartBubble
          bubbleData={displayData.data}
          {...displayData.config}
          timeScale={zoomedTimeScale}
          {...newSharedProps}
        />
      );
    case ChartTypes.chartRangeBar:
      return (
        <ChartRangeBar
          {...displayData.config}
          rangeBarData={displayData.data}
          timeScale={zoomedTimeScale}
          {...newSharedProps}
        />
      );
    default:
      return <></>;
  }
};

const Today = styled('div')<{x: number}>(({ x }) => ({
  // Make sure the line doesn't "end" on the bottom
  // if we have a vertically short timeline, but also
  // needs to extend down if we have a really long one
  minHeight: '100vh',
  height: '100%',
  borderRight: `1px solid ${colors.light110}`,
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: x,
}));

type InnerTimelineProps = {
  height: number;
  width: number;
  shouldShowFilters: boolean;
  minMaxDates: Date[];
  visibleDisplayData: FilteredDisplayData[];
};

const InnerTimeline = ({
  height,
  width,
  shouldShowFilters,
  minMaxDates,
  visibleDisplayData,
}: InnerTimelineProps) => {
  const { isDownloadMode } = useContext(DownloadModeContext);

  const timeScale = getTimeScale({
    dates: minMaxDates,
    xMin: timescalePadding,
    // Give extra room for wide labels
    xMax: width - 150,
  });

  const sharedProps = {
    width,
  };

  const today = useToday();

  return (
    <>
      <Zoom width={width} height={height} passive={true}>
        {(zoom) => {
          const handleZoomIn = () => zoom.scale({ scaleX: zoomFactor });
          const handleZoomOut = () => zoom.scale({ scaleX: 1 / zoomFactor });
          const zoomedTimeScale = rescaleXAxis(timeScale, zoom);
          const handleDoubleClick = (
            event: React.MouseEvent<HTMLDivElement, MouseEvent>
          ) => {
            const point = localPoint(event) || { x: 0, y: 0 };
            zoom.scale({
              scaleX: clickZoomFactor,
              scaleY: clickZoomFactor,
              point,
            });
            analytics.track({
              event: EVENTS.TIMELINE_INTERACTION_ZOOM,
              properties: {
                zoomMethod: "doubleClick",
                zoomDirection: "in",
              },
            });
          };

          const handleMouseLeave = () => {
            if (zoom.isDragging) {
              handleDragEnd();
            };
          };

          const handleKeyPress = (event: React.KeyboardEvent) => {
            // = and +
            const zoomInCharCodes = [43, 61];
            // - and _
            const zoomOutCharCodes = [45, 95];

            if (zoomInCharCodes.includes(event.charCode)) {
              handleZoomIn();
              analytics.track({
                event: EVENTS.TIMELINE_INTERACTION_ZOOM,
                properties: {
                  zoomMethod: "keypress",
                  zoomDirection: "in",
                },
              });
            } else if (zoomOutCharCodes.includes(event.charCode)) {
              handleZoomOut();
              analytics.track({
                event: EVENTS.TIMELINE_INTERACTION_ZOOM,
                properties: {
                  zoomMethod: "keypress",
                  zoomDirection: "out",
                },
              });
            }
          };

          const todayX = zoomedTimeScale(today);

          const handleDragEnd = () => {
            zoom.dragEnd();
            analytics.track({
              event: EVENTS.TIMELINE_INTERACTION_PAN,
            });
          }

          return (
            <>
              <TimelineShifter shouldShift={shouldShowFilters}>
                <Ticker timeScale={zoomedTimeScale} {...sharedProps} />
              </TimelineShifter>
              <Main constrainHeight={!isDownloadMode} id="main">
                <div
                  onTouchStart={zoom.dragStart}
                  onTouchMove={zoom.dragMove}
                  onTouchEnd={handleDragEnd}
                  onMouseDown={zoom.dragStart}
                  onMouseMove={zoom.dragMove}
                  onMouseUp={handleDragEnd}
                  onMouseLeave={handleMouseLeave}
                  onDoubleClick={handleDoubleClick}
                  style={{
                    cursor: zoom.isDragging ? "grabbing" : "grab",
                    position: 'relative',
                  }}
                  onKeyPress={handleKeyPress}
                  tabIndex={-1}
                >
                  {!isDownloadMode && (
                    <ZoomControls
                      onZoomIn={handleZoomIn}
                      onZoomOut={handleZoomOut}
                    />
                  )}
                  <TimelineShifter shouldShift={shouldShowFilters}>
                    <Today x={todayX} />
                    {visibleDisplayData.map((dd) => {
                      if (!dd.data.length) {
                        return null;
                      }

                      return (
                        <Category
                          key={dd.config.text.plural}
                          text={dd.config.text}
                          chartCategory={dd.config.category}
                          shouldTopAlign={
                            true
                            // dd.chartType === ChartTypes.chartRangeBar
                          }
                        >
                          {getChart({
                            displayData: dd,
                            zoomedTimeScale,
                            sharedProps,
                            label: dd.config.text.plural,
                          })}
                        </Category>
                      );
                    })}
                  </TimelineShifter>
                </div>
              </Main>
            </>
          );
        }}
      </Zoom>
    </>
  );
};
